import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/styles';
import { loginButton, loginFontTheme, loginTheme } from '../../theme.styles';
import './login.sass';
import {
    FormControl,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Input,
    Typography,
    Card,
    CardContent,
} from '@material-ui/core';
import { DataFormLogin, DataControlModalParent, User } from '../../interfaces';
import MailIcon from '@material-ui/icons/Mail';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStyles } from './login.styles-matui';
import {
    VACIO,
    STATUS,
    NAME_COOKIE_USERS,
    NAME_COOKIE_ADMINS,
} from '../../constants';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ADMIN, LOGIN_USER } from '../../graphql';
import { useMutation } from '@apollo/client';
import { LoginSpinner } from '../spinner/spinner.compt';
import Snackbars from '../snackbar/snackbar.compt';
import { useCookies } from 'react-cookie';
import { persistDataLogin } from './login.service';
import { Administrador } from '../administradores/administradores.interfaces';
import { useDispatch } from 'react-redux';
import { setUserLoginAction } from './login.actions';
import LoginLogo from './login-icon';

export default function ModalLogin() {
    const navigate = useNavigate();
    const initForm = new DataFormLogin(VACIO, VACIO, false, false);
    const classes = useStyles();
    const [formData, setFormData] = useState<DataFormLogin>(initForm);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [openSpinner, setOpenSpinner] = useState(false);
    const [loginAdmin] = useMutation(LOGIN_ADMIN);
    const [loginUser] = useMutation(LOGIN_USER);
    const [, setCookie] = useCookies<string>(['user']);
    const [, setOpenLoginModal] = useState<boolean>(true);
    const dispatch = useDispatch();

    const submitLogin = () => {
        setOpenSpinner(true);
        const login = {
            correo: formData.correo,
            password: formData.password,
        };

        let promiseLogin: Promise<any>;
        if (formData.banAdmin) {
            promiseLogin = loginAdmin({ variables: { login } });
        } else {
            promiseLogin = loginUser({ variables: { login } });
        }

        promiseLogin
            .then((resp) => {
                if (formData.banAdmin) {
                    const admin: Administrador =
                        resp.data.iniciarSesionAdministrador;
                    setCookie(NAME_COOKIE_ADMINS, admin.idAdministrador);
                    persistDataLogin(admin, NAME_COOKIE_ADMINS);
                    dispatch({ type: setUserLoginAction.type, data: admin });
                } else {
                    const user: User = resp.data.iniciarSesionUsuario;
                    setCookie(NAME_COOKIE_USERS, user.idUsuario);
                    persistDataLogin(user, NAME_COOKIE_USERS);
                    dispatch({ type: setUserLoginAction.type, data: user });
                }

                setOpenLoginModal(false);
                setFormData(initForm);
                navigate(
                    formData.banAdmin
                        ? '/ccap/admin/menu'
                        : '/ccap/users/cursos',
                );
                setOpenSpinner(false);
            })
            .catch(() => {
                openSnackBar(`Error al iniciar sesión`, STATUS.error);
                setOpenSpinner(false);
            });
    };

    const onChangeForm =
        (prop: keyof DataFormLogin) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (prop === 'showPassword' || prop === 'banAdmin') {
                setFormData({ ...formData, [prop]: !formData[prop] });
            } else {
                setFormData({ ...formData, [prop]: event.target.value });
            }
        };

    const onClickShowPassword = () => {
        setFormData({ ...formData, showPassword: !formData.showPassword });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
    };

    const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, banAdmin: !formData.banAdmin });
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    return (
        <div id='loginContent'>
            <Card>
                <CardContent className='cardContentLogin'>
                    <div className='containerModalLogin'>
                        <div className='divIconForm'>
                            <LoginLogo />
                        </div>
                        <ThemeProvider theme={loginFontTheme}>
                            <Typography
                                id='form-dialog-title'
                                variant='h5'
                                component='div'
                                className={classes.titleForm}
                            >
                                INICIO DE SESIÓN
                            </Typography>
                        </ThemeProvider>
                        <ThemeProvider theme={loginTheme}>
                            <DialogContent className={classes.dialogContent}>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <Input
                                        id='correo'
                                        placeholder='Correo electronico'
                                        type='email'
                                        value={formData.correo}
                                        onChange={onChangeForm('correo')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <ThemeProvider
                                                    theme={loginTheme}
                                                >
                                                    <MailIcon color='primary' />
                                                </ThemeProvider>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <Input
                                        placeholder='Contraseña'
                                        id='password'
                                        type={
                                            formData.showPassword
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={formData.password}
                                        onChange={onChangeForm('password')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <ThemeProvider
                                                    theme={loginTheme}
                                                >
                                                    <IconButton
                                                        aria-label='password'
                                                        color='primary'
                                                        onClick={
                                                            onClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge='end'
                                                    >
                                                        {formData.showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </ThemeProvider>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>

                                <div className='divCheckbox'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color='secondary'
                                                checked={formData.banAdmin}
                                                onChange={onChangeCheckbox}
                                                inputProps={{
                                                    'aria-label':
                                                        'primary checkbox',
                                                }}
                                            />
                                        }
                                        label='Soy administrador'
                                    />
                                </div>
                            </DialogContent>
                        </ThemeProvider>

                        <DialogActions className={classes.dialogActions}>
                            <ThemeProvider theme={loginButton}>
                                <Button
                                    onClick={submitLogin}
                                    color='primary'
                                    variant='contained'
                                    className={classes.buttonLogin}
                                >
                                    ENTRAR
                                </Button>
                            </ThemeProvider>
                        </DialogActions>
                    </div>
                </CardContent>
            </Card>

            <LoginSpinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />
            <Snackbars options={snackbar} />
        </div>
    );
}
