import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    ThemeProvider,
} from '@material-ui/core';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { useQontoStepIconStyles, QontoConnector } from './sesion-test.mat-ui';
import { DataControlModalParent, ExamPropsAll } from '../../../interfaces';
import Snackbars from '../../snackbar/snackbar.compt';
import Spinner from '../../spinner/spinner.compt';
import Check from '@material-ui/icons/Check';
import { VACIO, NAME_COOKIE_USERS, STATUS } from '../../../constants';
import { SET_TEST_USER } from '../courses-users.grapqhl';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { setUserLoginAction } from '../../login/login.actions';
import { persistDataLogin } from '../../login/login.service';
import { Curso } from '../../cursos/cursos.interfaces';
import { useSelector } from 'react-redux';
import { sesionSelect } from '../courses-actions';
import { modalTitleFontTheme, useGeneralStyles } from '../../../styles-matui';
import { themeButtons } from '../../../theme.styles';
import { useStyles } from '../courses-users.mat-ui';
import clsx from 'clsx';
import './sesion-test.sass';
import { Reducers } from '../../../store';

function QontoStepIcon(props: StepIconProps) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

export default function SesionTest(props) {
    const [openSpinner, setOpenSpinner] = useState(false);
    const classes = useStyles();
    const generalClasses = useGeneralStyles();
    const dispatch = useDispatch();
    const [setNewTestUser] = useMutation(SET_TEST_USER);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });

    const [activeStep, setActiveStep] = React.useState(0);
    const [dataStep, setDataStep] = React.useState<ExamPropsAll>(
        props.options.test
            ? props.options.test[0]
            : {
                  preguntas: [],
                  focus: {
                      pregunta: VACIO,
                      respuestas: [],
                      seleccionada: VACIO,
                  },
              },
    );
    const steps = props.options.test ? props.options.test : [];
    const curso = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.course,
    ) as Curso;
    const modulo = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.module,
    ) as any;
    const sesion = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.sesion,
    ) as any;
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (props.options.test) {
            try {
                const newPreguntas = props.options.test.map((preg) => {
                    return {
                        ...preg,
                        respuestas: newOrder(preg.respuestas),
                    };
                });

                setDataStep({
                    preguntas: newPreguntas,
                    focus: newPreguntas[0],
                });

                setActiveStep(0);
            } catch (error) {
                setError(true);
                openSnackBar(
                    `Error al cargar examen, intenta más adelante o contacta al administrador`,
                    STATUS.error,
                );
            }
        }
    }, [props.options.test]);

    const updateData = (user: any) => {
        const dataC = user.cursos.find((itm) => itm.idCurso === curso.idCurso);
        const dataM =
            dataC && dataC.modulos
                ? dataC.modulos.find((val) => val.idModulo === modulo.idModulo)
                : undefined;
        const dataS =
            dataM && dataM.sesiones
                ? dataM.sesiones.find((val) => val.idSesion === sesion.idSesion)
                : undefined;

        const data = {
            ...sesion,
            intentosExamen:
                dataS && dataS.examen && dataS.examen !== null
                    ? dataS.examen
                    : [],
            banDescargaDocumento:
                dataS && dataS.banDescargaDocumento
                    ? dataS.banDescargaDocumento
                    : false,
            videos:
                dataS && dataS.videos && dataS.videos !== null
                    ? sesion.videos.map((vid) => {
                          const vidUsr: any = dataS.videos;
                          const dataVid = vidUsr.find(
                              (video) => video.idVideo === vid.idVideo,
                          );

                          return {
                              ...vid,
                              ...dataVid,
                          };
                      })
                    : sesion.videos,
            porcentaje:
                dataS && dataS.porcentaje && dataS.porcentaje !== null
                    ? dataS.porcentaje
                    : 0,
        };

        dispatch({ type: sesionSelect.type, sesion: data });
    };

    const newOrder = (resp: any[]): any[] => {
        const lastresp: any[] = [];
        let max = 0;
        let newresp: any[] = [];

        if (resp.length === 0) {
            return resp;
        }

        if (resp.length > 4) {
            newresp.push(resp[0]);

            while (newresp.length < 4) {
                const rand = Math.floor(Math.random() * resp.length);
                if (
                    newresp.filter(
                        (res: any) => res.respuesta === resp[rand].respuesta,
                    ).length === 0
                ) {
                    newresp.push(resp[rand]);
                }
            }

            max = 4;
        } else {
            newresp = resp;
            max = resp.length;
        }

        while (lastresp.length < max) {
            const rand = Math.floor(Math.random() * newresp.length);
            if (lastresp.length === 0) {
                lastresp.push(newresp[rand]);
            } else if (
                lastresp.filter(
                    (res: any) => res.respuesta === newresp[rand].respuesta,
                ).length === 0
            ) {
                lastresp.push(newresp[rand]);
            }
        }

        return lastresp;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataStep({
            preguntas: dataStep.preguntas.map((item) =>
                item.pregunta === dataStep.focus.pregunta
                    ? {
                          ...item,
                          seleccionada: (event.target as HTMLInputElement)
                              .value,
                      }
                    : item,
            ),
            focus: {
                ...dataStep.focus,
                seleccionada: (event.target as HTMLInputElement).value,
            },
        });
    };

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            const requestTest = {
                ...props.options.course,
                test: dataStep.preguntas.map((preg: any) => {
                    return {
                        pregunta: preg.pregunta,
                        respuesta: preg.seleccionada,
                    };
                }),
            };

            let serviceResponse;
            setOpenSpinner(true);
            setNewTestUser({
                variables: {
                    newSetTest: requestTest,
                },
            })
                .then((response: any) => {
                    serviceResponse = response.data.setNewTest;
                    persistDataLogin(serviceResponse, NAME_COOKIE_USERS);
                    dispatch({
                        type: setUserLoginAction.type,
                        data: serviceResponse,
                    });
                    updateData(serviceResponse);
                    setOpenSpinner(false);
                    props.options.closeParent();
                })
                .catch((error) => {
                    setOpenSpinner(false);
                    props.options.closeParent();
                    if (
                        error &&
                        error.message &&
                        error.message.includes('state mutation')
                    ) {
                        dispatch({
                            type: setUserLoginAction.type,
                            data: serviceResponse,
                        });
                    }
                });
        } else {
            setDataStep({
                ...dataStep,
                focus: dataStep.preguntas[activeStep + 1],
            });
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === steps.length) {
            setDataStep({
                ...dataStep,
                focus: dataStep.preguntas[activeStep - 2],
            });
        } else {
            setDataStep({
                ...dataStep,
                focus: dataStep.preguntas[activeStep - 1],
            });
        }

        setActiveStep((prevActiveStep) =>
            prevActiveStep === steps.length
                ? prevActiveStep - 2
                : prevActiveStep - 1,
        );
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const onClickDialogOpenClose = () => {
        props.options.closeParent();
    };

    if (error) {
        return (
            <div>
                <Snackbars options={snackbar} />
            </div>
        );
    }

    return (
        <div>
            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />

            <Dialog
                maxWidth='md'
                scroll='body'
                open={props.options.open}
                onClose={onClickDialogOpenClose}
                aria-labelledby='form-dialog-title'
                className={generalClasses.modal}
            >
                <div className='containerModalTest'>
                    <DialogTitle
                        id='form-dialog-title-exam'
                        className={generalClasses.titleForm}
                    >
                        <ThemeProvider theme={modalTitleFontTheme}>
                            <Typography
                                gutterBottom
                                variant='h5'
                                component='div'
                                color='primary'
                            >
                                EXAMEN
                            </Typography>
                        </ThemeProvider>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <Stepper
                                alternativeLabel
                                activeStep={activeStep}
                                connector={<QontoConnector />}
                            >
                                {steps.map((item, index) => (
                                    <Step key={index}>
                                        <StepLabel
                                            StepIconComponent={QontoStepIcon}
                                        ></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            <div>
                                <Typography variant='subtitle1' component='h2'>
                                    {`${
                                        activeStep === steps.length
                                            ? activeStep
                                            : activeStep + 1
                                    }.- ${
                                        dataStep.focus.pregunta
                                            ? dataStep.focus.pregunta
                                            : ''
                                    }`}
                                </Typography>
                                <FormControl component='fieldset'>
                                    <RadioGroup
                                        aria-label='gender'
                                        name='gender1'
                                        value={dataStep.focus.seleccionada}
                                        onChange={handleChange}
                                    >
                                        {dataStep.focus.respuestas.map(
                                            (item, pos) => (
                                                <FormControlLabel
                                                    key={pos}
                                                    value={item.respuesta}
                                                    control={<Radio />}
                                                    label={item.respuesta}
                                                />
                                            ),
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className={generalClasses.dialogActions}>
                        <div className='containerActions'>
                            <ThemeProvider theme={themeButtons}>
                                <Button
                                    className={classes.buttonLeft}
                                    variant='contained'
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    color='secondary'
                                >
                                    Anterior
                                </Button>

                                <Button
                                    className={classes.button}
                                    disabled={
                                        dataStep.focus.seleccionada === VACIO
                                    }
                                    variant='contained'
                                    color='primary'
                                    onClick={handleNext}
                                >
                                    {activeStep === steps.length - 1 ||
                                    activeStep === steps.length
                                        ? 'Terminar'
                                        : 'Siguiente'}
                                </Button>
                            </ThemeProvider>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
            <Snackbars options={snackbar} />
        </div>
    );
}
