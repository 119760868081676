import { createTheme } from '@material-ui/core/styles';
import { red, amber, green, grey } from '@material-ui/core/colors';

export const theme = createTheme({
    typography: {
        fontFamily: 'Caviar',
    },
    palette: {
        primary: {
            main: '#263680',
        },
        secondary: {
            main: '#263680',
        },
        error: {
            main: red[900],
        },
        warning: {
            main: amber[500],
        },
        info: {
            main: '#263680',
        },
        success: {
            main: green[500],
        },
    },
});

export const themeButtons = createTheme({
    typography: {
        fontFamily: 'CaviarBold',
    },
    palette: {
        primary: {
            main: '#263680',
        },
        secondary: {
            main: '#2f2e2e',
        },
        info: {
            main: '#263680',
        },
    },
});

export const loginButton = createTheme({
    typography: {
        fontFamily: 'LemonBold',
    },
    palette: {
        primary: {
            main: '#263680',
        },
        secondary: {
            main: grey[400],
        },
        info: {
            main: '#263680',
        },
    },
});

export const loginFontTheme = createTheme({
    typography: {
        fontFamily: 'LemonBold',
    },
});

export const loginTheme = createTheme({
    typography: {
        fontFamily: 'Caviar',
    },
    palette: {
        primary: {
            main: '#263680',
        },
        secondary: {
            main: '#2f2e2e',
        },
        error: {
            main: red[900],
        },
        warning: {
            main: amber[500],
        },
        info: {
            main: '#263680',
        },
        success: {
            main: green[500],
        },
    },
});
