import {
    Administrador,
    EditAdministrador,
    EditarPermisos,
} from './components/administradores/administradores.interfaces';
import {
    Usuario,
    EditUsuario,
} from './components/usuarios/usuarios.interfaces';
import {
    AdministradoresCursosData,
    Curso,
} from './components/cursos/cursos.interfaces';

export class DataFormLogin {
    public correo: string;
    public password: string;
    public showPassword: boolean;
    public banAdmin: boolean;

    constructor(
        correo: string,
        password: string,
        showPassword: boolean,
        banAdmin: boolean,
    ) {
        this.correo = correo;
        this.password = password;
        this.showPassword = showPassword;
        this.banAdmin = banAdmin;
    }
}

export interface ActionReducer {
    type: string;
    payload: any;
    idAdministrador: string;
    idUsuario: string;
    editPermisosAdmin: EditarPermisos;
    idCurso: string;
    permisosAdminCurso: string[];
}

export interface InitialStateAdmins {
    inicial: boolean;
    administradores: Administrador[];
}

export interface InitialStateUsers {
    inicial: boolean;
    usuarios: Usuario[];
}

export interface DataUser {
    fechaAlta: string;
    administrador: string;
    foto: string;
    nombres: string;
    apellidos: string;
    celular: string;
    correo: string;
    dependencia: string;
    nivelAcadem: string;
    carreraProf: string;
    puesto: string;
    curriculum: string;
    tipo: string;
    idUsuario: string;
    cursos: UserCourses[];
}

export interface User {
    fechaAlta?: string;
    administrador?: string;
    foto?: string;
    nombres?: string;
    apellidos?: string;
    celular?: string;
    correo?: string;
    dependencia?: string;
    nivelAcadem?: string;
    carreraProf?: string;
    puesto?: string;
    curriculum?: string;
    tipo?: string;
    idUsuario?: string;
    cursos?: UserCourses[];
    checked?: boolean;
}

export interface UserCourses {
    fechaAlta: string;
    idCurso: string;
    fechaTermino: string;
    porcentaje: number;
    banDiploma: boolean;
    modulos: ModuloUsuario[] | null;
}

export interface ModuloUsuario {
    idModulo: string;
    fechaAlta: string;
    fechaTermino: string;
    porcentaje: number;
    banDiploma: boolean;
    sesiones: SesionUsuario[] | null;
}

export interface SesionUsuario {
    idSesion: string;
    fechaAlta: string;
    fechaTermino: string;
    porcentaje: number;
    banDescargaDocumento: boolean;
    videos: VideosCurso[] | null;
    examen: ExamenCurso[] | null;
}

export interface ExamenCurso {
    intento: number;
    calificacion: number;
    preguntas: PreguntasCurso[] | null;
}

export interface PreguntasCurso {
    pregunta: string;
    respuestas: RespuestasExamen[] | null;
}

export interface RespuestasExamen {
    respuesta: string;
    correcta: boolean;
}

export interface VideosCurso {
    idVideo: string;
    minuto: string;
    porcentaje: number;
}

export interface DataControlModalParent {
    open: boolean;
    descripcion?: string;
    dataEditAdmin?: EditAdministrador;
    dataEditUser?: EditUsuario;
    closeParent?: any;
    accion?: string;
    uid?: string;
    dataPermisos?: EditarPermisos;
    status?: string;
    test?: any[];
    course?: any;
}

export interface InitialStateCursos {
    inicial: boolean;
    cursos: Curso[];
    idCursoSelect: string;
    nuevo: {
        administradores: AdministradoresCursosData;
        asignacionUsuarios: User[];
    };
}

export interface CardNav {
    data: CardNavData[];
    route: string;
    dashboardAdmin?: boolean;
}

export class CardNavData {
    public foto: string;
    public nombre: string;
    public descripcionCorta: string;
    public porcentaje: number | null;
    public banDiploma: boolean;
    public route?: string;
    public idModulo?: string;
    public idSesion?: string;
    public idCurso?: string;
    public sesiones?: any[];
    public modulos?: any[];

    constructor(
        foto: string,
        nombre: string,
        descripcionCorta: string,
        porcentaje: number | null,
        banDiploma: boolean,
        route?: string,
        idModulo?: string,
        idSesion?: string,
        idCurso?: string,
        sesiones?: any[],
        modulos?: any[],
    ) {
        this.foto = foto;
        this.nombre = nombre;
        this.descripcionCorta = descripcionCorta;
        this.porcentaje = porcentaje;
        this.banDiploma = banDiploma;
        this.idModulo = idModulo;
        this.idSesion = idSesion;
        this.idCurso = idCurso;
        this.sesiones = sesiones;
        this.modulos = modulos;
        this.route = route;
    }
}

export interface DetailTitleInfo {
    data: DetailTitleData;
}

export interface DetailTitleData {
    nombre: string;
    descripcionLarga: string;
    foto: string;
    progressUser: number;
    progressSystem: number;
    calificacion: number;
}

export interface InfoComponetMultimedia {
    data: InfoCardMultimedia;
    cardMultimediaEvent: any;
}

export interface InfoCardMultimedia {
    url: string;
    type: string;
    title: string;
    name: string;
    porcentaje: number;
    download: boolean;
    banTest: boolean;
    intentosExamen: any[];
    idModulo: string;
    idCurso: string;
    idSesion: string;
    idVideo: string;
    currentTime: number;
}

export interface Times {
    currentTime: number;
    duration: number;
    percentage: number;
}

export interface CircularProgressData {
    data: {
        porcentaje: number | null;
    };
}

export interface ExamPropsAll {
    preguntas: ExamProps[];
    focus: ExamProps;
}

export interface ExamProps {
    pregunta: string;
    respuestas: RespuestaExamProps[];
    seleccionada: string;
}

export interface RespuestaExamProps {
    respuesta: string;
    correcta?: boolean;
}

export interface DataProgressUser {
    data: {
        progressUser: number;
        progressSystem: number;
        calificacion: number;
    };
}

export interface DataProgressBar {
    data: {
        progressUser: number;
        progressSystem: number;
    };
}
