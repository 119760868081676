import Regresar from '../../regresar/regresar.compt';
import { useSelector } from 'react-redux';
import CardNavInfo from '../../card-nav/card-nav.compt';
import { DataUser } from '../../../interfaces';
import { Curso } from '../../cursos/cursos.interfaces';
import { ThemeProvider, Typography } from '@material-ui/core';
import { Reducers } from '../../../store';
import { modalTitleFontTheme } from '../../../styles-matui';

export default function SesionsCourse() {
    const user = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as DataUser;
    const curso = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.course,
    ) as Curso;
    const modulo = useSelector<Reducers>(
        (reducers) => reducers.courseSelectReducer.module,
    ) as any;
    const sesions = modulo.sesiones;

    const setPercent = (sesion) => {
        const dataC = user.cursos.find((itm) => itm.idCurso === curso.idCurso);
        const dataM =
            dataC && dataC.modulos
                ? dataC.modulos.find((val) => val.idModulo === modulo.idModulo)
                : undefined;
        const dataS =
            dataM && dataM.sesiones
                ? dataM.sesiones.find((val) => val.idSesion === sesion.idSesion)
                : undefined;

        return {
            ...sesion,
            intentosExamen:
                dataS && dataS.examen && dataS.examen !== null
                    ? dataS.examen
                    : [],
            banDescargaDocumento:
                dataS && dataS.banDescargaDocumento
                    ? dataS.banDescargaDocumento
                    : false,
            videos:
                dataS && dataS.videos && dataS.videos !== null
                    ? sesion.videos.map((vid) => {
                          const vidUsr: any = dataS.videos;
                          const dataVid = vidUsr.find(
                              (video) => video.idVideo === vid.idVideo,
                          );

                          return {
                              ...vid,
                              ...dataVid,
                          };
                      })
                    : sesion.videos,
            porcentaje:
                dataS && dataS.porcentaje && dataS.porcentaje !== null
                    ? dataS.porcentaje
                    : 0,
        };
    };

    return (
        <div>
            <Regresar route='/ccap/users/modulo' />
            <ThemeProvider theme={modalTitleFontTheme}>
                <Typography
                    gutterBottom
                    variant='h4'
                    component='h2'
                    color='primary'
                >
                    {modulo.nombre}
                </Typography>
            </ThemeProvider>
            <CardNavInfo
                data={sesions.map((ses) => setPercent(ses))}
                route='/ccap/users/sesion'
            />
        </div>
    );
}
