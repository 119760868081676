import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        imgperfil: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
        displayNone: {
            display: 'none!important',
        },
        list: {
            width: 250,
            marginTop: '70px',
        },
        fullList: {
            width: 'auto',
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            background: '#ffffff',
        },
    }),
);
