import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { red, green, blue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginBottom: '16px',
            maxWidth: 400,
            width: '100%',
        },
        menuItem: {
            padding: '12px',
        },
        title: {
            marginBottom: '24px',
            fontFamily: 'LemonBold',
        },
        containerReport: {
            padding: '20px',
            width: '200px',
            height: '50px',
            border: 'solid 1px #000',
        },
        comboCursos: {
            marginRight: '16px',
            marginBottom: '16px',
            maxWidth: 400,
            width: '100%',
            [theme.breakpoints.up('xs')]: {
                marginRight: '0px',
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: '16px',
            },
        },
        displayNone: {
            display: 'none',
        },
        titleModulo: {
            minWidth: '80px',
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
        },
        paperUser: {
            width: '100%',
            marginBottom: '24px',
            padding: '16px',
        },
        avatar: {
            height: '120px',
            width: '120px',
        },
        titleSesion: {
            fontSize: '18px',
            marginBottom: '8px',
        },
        dataSesion: {
            display: 'flex',
            alignItems: 'center',
        },
        iconDataSesion: {
            color: '#263680',
            marginRight: '8px',
        },
        iconDoneDataSesion: {
            color: green[500],
            marginRight: '8px',
        },
        iconErrorDataSesion: {
            color: red[500],
            marginRight: '8px',
        },
    }),
);
