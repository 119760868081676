import {
    createStyles,
    makeStyles,
    Theme,
    lighten,
    createTheme,
} from '@material-ui/core/styles';

export const modalTitleFontTheme = createTheme({
    typography: {
        fontFamily: 'LemonBold',
    },
    palette: {
        primary: {
            main: '#263680',
        },
    },
});

export const useGeneralStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        formControl: {
            maxWidth: '400px',
            width: '100%',
            marginBottom: '16px',
            [theme.breakpoints.up('xs')]: {
                maxWidth: '100%',
            },
        },
        titleForm: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '24px 0px',
            margin: '0px',
        },
        modal: {
            padding: '16px',
        },
        dialogActions: {
            padding: '0px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10px',
        },
        checkbox: {
            marginBottom: '-5px',
        },
        tableRowDisable: {
            backgroundColor: '#f5f5f5',
        },
        btnRegresar: {
            padding: '4px 8px 4px 0px',
            marginBottom: '16px',
        },
        iconRegresar: {
            marginRight: '8px',
        },
        linearProgress: {
            padding: '4px',
            marginBottom: '32px',
        },
        menuItem: {
            padding: '12px',
        },
        fotoInput: {
            display: 'none',
        },
    }),
);

export const useGeneralToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                      color: theme.palette.secondary.main,
                      backgroundColor: lighten(
                          theme.palette.secondary.light,
                          0.85,
                      ),
                  }
                : {
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.secondary.dark,
                  },
        title: {
            flex: '1 1 100%',
            fontFamily: 'LemonBold',
        },
        todosButtonGroup: {
            marginRight: '10px',
        },
        todosActionButtons: {
            padding: '8px',
        },
        todosActionButtonsOcult: {
            display: 'none',
        },
    }),
);
