import { CardNavData } from '../../interfaces';
import CardNavInfo from '../card-nav/card-nav.compt';
import adminsIcon from '../../assets/img/admins-icon.png';
import coursesIcon from '../../assets/img/courses-icon.png';
import reportsIcon from '../../assets/img/reports-icon.png';
import usersIcon from '../../assets/img/users-icon.png';
import './menu-ccap.sass';

export default function MenuCCAP() {
    const options = [
        {
            nombre: 'Usuarios',
            imagen: usersIcon,
            route: '/ccap/admin/usuarios',
            descripcion:
                'Administra a los usuarios del centro de capacitación.',
        },
        {
            nombre: 'Cursos',
            imagen: coursesIcon,
            route: '/ccap/admin/cursos/lista',
            descripcion: 'Administra los cursos del centro de capacitación.',
        },
        {
            nombre: 'Reportes',
            imagen: reportsIcon,
            route: '/ccap/admin/reportes',
            descripcion:
                'Visualiza y descarga reportes del centro de capacitación.',
        },
        {
            nombre: 'Administradores',
            imagen: adminsIcon,
            route: '/ccap/admin/administradores',
            descripcion:
                'Administra a los usuarios administradores del centro de capacitación.',
        },
    ];

    return (
        <CardNavInfo
            data={options.map(
                (item) =>
                    new CardNavData(
                        item.imagen,
                        item.nombre,
                        item.descripcion,
                        0,
                        false,
                        item.route,
                    ),
            )}
            route='/ccap/admin/menu'
            dashboardAdmin={true}
        />
    );
}
