import React, { useState } from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {
    HeadCellUser,
    Usuario,
    EnhancedTableUserProps,
    Order,
    PropsEnhancedTableUsersToolbar,
    EditUsuario,
} from '../usuarios.interfaces';
import { useStyles, useToolbarStyles } from '../usuarios.styles-matui';
import { ButtonGroup, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import ModalNuevoUsuario from '../nuevo-usuario/nuevo-usuario.compt';
import Tooltip from '@material-ui/core/Tooltip';
import ModalConfirmaciones from '../../modal-confirmaciones/modal-confirmaciones.compt';
import { DataControlModalParent } from '../../../interfaces';
import ModalEditarUsuario from '../editar-usuario/editar-usuario.compt';
import {
    VACIO,
    ROLES_USUARIOS,
    STATUS,
    DATA_USER_INIT,
} from '../../../constants';
import { useSelector } from 'react-redux';
import {
    DELETE_USER,
    INHABILITAR_USER,
    HABILITAR_USER,
} from '../../../graphql';
import { useMutation } from '@apollo/client';
import Spinner from '../../spinner/spinner.compt';
import ModalCambiarPassword from '../../cambiar-password/cambiar-password.compt';
import { useGeneralStyles } from '../../../styles-matui';
import Snackbars from '../../snackbar/snackbar.compt';
import Avatar from '@material-ui/core/Avatar';
import { Administrador } from '../../administradores/administradores.interfaces';
import { Reducers } from '../../../store';

const HeadCellAdmins: HeadCellUser[] = [
    {
        id: 'nombreCompleto',
        numeric: false,
        disablePadding: true,
        label: 'Nombre completo',
    },
    { id: 'correo', numeric: false, disablePadding: false, label: 'Correo' },
    { id: 'celular', numeric: false, disablePadding: false, label: 'Celular' },
    {
        id: 'banInactivo',
        numeric: false,
        disablePadding: false,
        label: 'Estatus',
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<Usuario>(
    array: Usuario[],
    comparator: (a: any, b: any) => number,
) {
    const stabilizedThis = array.map(
        (el, index) => [el, index] as [any, number],
    );
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props: EnhancedTableUserProps) => {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof Usuario) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>

                <TableCell align='left'>Foto</TableCell>

                {HeadCellAdmins.map((HeadCellAdmin) => (
                    <TableCell
                        key={HeadCellAdmin.id}
                        align='left'
                        sortDirection={
                            orderBy === HeadCellAdmin.id ? order : false
                        }
                    >
                        <TableSortLabel
                            active={orderBy === HeadCellAdmin.id}
                            direction={
                                orderBy === HeadCellAdmin.id ? order : 'asc'
                            }
                            onClick={createSortHandler(HeadCellAdmin.id)}
                        >
                            {HeadCellAdmin.label}
                            {orderBy === HeadCellAdmin.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

                <TableCell align='center'>Acciones</TableCell>
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = (props: PropsEnhancedTableUsersToolbar) => {
    const classes = useToolbarStyles();
    const [deleteAdmin] = useMutation(DELETE_USER);
    const [inhAdmin] = useMutation(INHABILITAR_USER);

    const inhabUser = (idUsuario: string) => {
        return new Promise((resolve, reject) => {
            inhAdmin({
                variables: {
                    user: {
                        uid: idUsuario,
                        tipo: ROLES_USUARIOS.USRO,
                    },
                },
            })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const deleteUser = (idUsuario: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            deleteAdmin({
                variables: {
                    user: {
                        uid: idUsuario,
                        tipo: ROLES_USUARIOS.USRO,
                    },
                },
            })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const deleteAny = () => {
        const usersAllowed = props.usersSelected;
        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay usuarios disponibles para eliminar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((user) => deleteUser(user));

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.refreshUsers('Exito al eliminar los usuarios');
                    props.setSelected([]);
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        `Error al eliminar los usuarios`,
                        STATUS.error,
                    );
                });
        }
    };

    const inhabAny = () => {
        const usersAllowed = props.usersSelected;
        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay usuarios disponibles para inhabilitar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((user) => inhabUser(user));

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.refreshUsers('Exito al inhabilitar los usuarios');
                    props.setSelected([]);
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        `Error al inhabilitar los usuarios`,
                        STATUS.error,
                    );
                });
        }
    };

    return (
        <Toolbar className={classes.root}>
            <Typography
                className={classes.title}
                variant='h6'
                id='tableTitle'
                component='div'
                color='primary'
            >
                Lista de usuarios
            </Typography>

            <ButtonGroup
                className={classes.todosButtonGroup}
                color='primary'
                aria-label='outlined primary button group'
            >
                <Button
                    onClick={inhabAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.usersSelected.length === 0,
                    })}
                >
                    <BlockIcon />
                </Button>
                <Button
                    onClick={deleteAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.usersSelected.length === 0,
                    })}
                >
                    <DeleteIcon />
                </Button>
            </ButtonGroup>
        </Toolbar>
    );
};

export default function UsuarioGeneral(props) {
    const classes = useStyles();
    const user = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as Administrador;
    const generalClasses = useGeneralStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Usuario>('nombreCompleto');
    const [selected, setSelected] = useState<string[]>([]);
    const [deleteAdmin] = useMutation(DELETE_USER);
    const [inhAdmin] = useMutation(INHABILITAR_USER);
    const [habAdmin] = useMutation(HABILITAR_USER);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [chgPass, setChgPass] = useState<DataControlModalParent>({
        open: false,
    });
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [confirmaciones, setConfirmaciones] =
        useState<DataControlModalParent>({ open: false, descripcion: '' });
    const dataEditInit: EditUsuario = DATA_USER_INIT;
    const [editarData, setEditarData] = useState<DataControlModalParent>({
        open: false,
        dataEditUser: dataEditInit,
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const usuarios = props.usuarios;

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Usuario,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            const newSelecteds = usuarios.map((n) => n.idUsuario);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (
        event: React.MouseEvent<unknown>,
        idUsuario: string,
    ) => {
        const selectedIndex = selected.indexOf(idUsuario);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, idUsuario);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const closeModalConfirmaciones = (
        value: boolean,
        accion: string,
        idUsuario: string,
    ) => {
        setConfirmaciones({ open: false, descripcion: '' });
        const userSelect: Usuario = usuarios.find(
            (user: Usuario) => user.idUsuario === idUsuario,
        );

        if (value) {
            setOpenSpinner(true);
        }

        if (value && accion === 'inhabilitar') {
            inhAdmin({
                variables: {
                    user: {
                        uid: idUsuario,
                        tipo: ROLES_USUARIOS.USRO,
                    },
                },
            })
                .then(() => {
                    props.refreshUsers(
                        `Exito al inhabilitar al usuario ${userSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al inhabilitar al usuario ${userSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'habilitar') {
            habAdmin({
                variables: {
                    user: {
                        uid: idUsuario,
                        tipo: ROLES_USUARIOS.USRO,
                    },
                },
            })
                .then(() => {
                    props.refreshUsers(
                        `Exito al habilitar al usuario ${userSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al habilitar al usuario ${userSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'eliminar') {
            deleteAdmin({
                variables: {
                    user: {
                        uid: idUsuario,
                        tipo: ROLES_USUARIOS.USRO,
                    },
                },
            })
                .then(() => {
                    props.refreshUsers(
                        `Exito al eliminar al usuario ${userSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al eliminar al usuario ${userSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        }
    };

    const closeModalEdicion = (success: boolean) => {
        setEditarData({
            open: false,
            closeParent: closeModalEdicion,
            dataEditUser: dataEditInit,
        });

        if (success) {
            props.refreshUsers('Exito al actualizar al usuario');
        }
    };

    const handleModalConfirmacionesInhabilitar = (idUsuario: string) => {
        const userSelect: Usuario = usuarios.find(
            (user) => user.idUsuario === idUsuario,
        );
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de ${
                userSelect.banInactivo ? 'habilitar' : 'inhabilitar'
            } a ${userSelect.nombreCompleto}?`,
            closeParent: closeModalConfirmaciones,
            accion: userSelect.banInactivo ? 'habilitar' : 'inhabilitar',
            uid: idUsuario,
        });
    };

    const handleModalConfirmacionesEliminar = (idUsuario: string) => {
        const userSelect: Usuario = usuarios.find(
            (user) => user.idUsuario === idUsuario,
        );
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de eliminar a ${userSelect.nombreCompleto}?`,
            closeParent: closeModalConfirmaciones,
            accion: 'eliminar',
            uid: idUsuario,
        });
    };

    const handleModalEditar = (idUsuario: string) => {
        const userSelect: Usuario = usuarios.find(
            (user) => user.idUsuario === idUsuario,
        );

        setEditarData({
            open: true,
            closeParent: closeModalEdicion,
            dataEditUser: {
                ...DATA_USER_INIT,
                ...userSelect,
                idUsuario: userSelect.idUsuario,
                nombres: userSelect.nombres,
                apellidos: userSelect.apellidos,
                correo: userSelect.correo,
                celular: userSelect.celular,
                puesto: userSelect.puesto,
                dependencia: userSelect.dependencia,
                carreraProfesion: userSelect.carreraProfesion,
                nivelAcademico: userSelect.nivelAcademico,
                banPerfil: false,
            },
        });
    };

    const closeModalRecuperarPassword = (value: boolean) => {
        setChgPass({ open: false, closeParent: closeModalRecuperarPassword });
    };

    const handleModalRecuperarPassword = (idUsuario: string) => {
        const userSelect: Usuario = usuarios.find(
            (user) => user.idUsuario === idUsuario,
        );
        setChgPass({
            open: true,
            closeParent: closeModalRecuperarPassword,
            uid: userSelect.idUsuario,
        });
    };

    const isSelected = (idUsuario: string) =>
        selected.indexOf(idUsuario) !== -1;

    return (
        <div className={classes.root}>
            <div
                className={
                    usuarios.length > 0
                        ? classes.displayNone
                        : classes.titleNoAdmins
                }
            >
                <Typography component='h4'>
                    <b>No hay usuarios para administrar aún</b>
                </Typography>
            </div>
            <Paper
                className={
                    usuarios.length === 0 ? classes.displayNone : classes.paper
                }
            >
                <EnhancedTableToolbar
                    course={VACIO}
                    setSelected={setSelected}
                    usuarios={usuarios}
                    usersSelected={selected}
                    openSnackBar={openSnackBar}
                    setOpenSpinner={setOpenSpinner}
                    refreshUsers={props.refreshUsers}
                />

                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size='medium'
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={usuarios.length}
                        />

                        <TableBody>
                            {stableSort(usuarios, getComparator(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row, index) => {
                                    const isItemSelected = isSelected(
                                        row.idUsuario,
                                    );
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            className={
                                                row.banInactivo
                                                    ? generalClasses.tableRowDisable
                                                    : ''
                                            }
                                            hover
                                            role='checkbox'
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.idUsuario}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding='checkbox'>
                                                <Checkbox
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idUsuario,
                                                        )
                                                    }
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            labelId,
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell align='center'>
                                                <Avatar
                                                    alt={row.nombreCompleto}
                                                    src={row.foto}
                                                />
                                            </TableCell>

                                            <TableCell
                                                component='th'
                                                id={labelId}
                                                scope='row'
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idUsuario,
                                                    )
                                                }
                                            >
                                                {row.nombreCompleto}
                                            </TableCell>

                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idUsuario,
                                                    )
                                                }
                                                align='left'
                                            >
                                                {row.correo}
                                            </TableCell>
                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idUsuario,
                                                    )
                                                }
                                                align='left'
                                            >
                                                {row.celular}
                                            </TableCell>
                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idUsuario,
                                                    )
                                                }
                                                align='left'
                                            >
                                                <b>
                                                    {row.banInactivo
                                                        ? 'Inactivo'
                                                        : 'Activo'}
                                                </b>
                                            </TableCell>

                                            <TableCell align='center'>
                                                <ButtonGroup
                                                    color='primary'
                                                    aria-label='outlined primary button group'
                                                >
                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null &&
                                                            !row.banInactivo
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM01',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnEditar'
                                                        onClick={() =>
                                                            handleModalEditar(
                                                                row.idUsuario,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Ver información'>
                                                            <VisibilityIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM04',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnInhabilitar'
                                                        onClick={() =>
                                                            handleModalConfirmacionesInhabilitar(
                                                                row.idUsuario,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip
                                                            title={
                                                                row.banInactivo
                                                                    ? 'Habilitar'
                                                                    : 'Inhabilitar'
                                                            }
                                                        >
                                                            <BlockIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null &&
                                                            !row.banInactivo
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM04',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnEliminar'
                                                        onClick={() =>
                                                            handleModalConfirmacionesEliminar(
                                                                row.idUsuario,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Eliminar'>
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            user.permisos &&
                                                            user.permisos !==
                                                                null &&
                                                            !row.banInactivo
                                                                ? !user.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADM06',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnPassword'
                                                        onClick={() =>
                                                            handleModalRecuperarPassword(
                                                                row.idUsuario,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Recuperar contraseña'>
                                                            <SettingsIcon />
                                                        </Tooltip>
                                                    </Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    labelRowsPerPage='Resultados por página'
                    count={usuarios.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />
            <div
                className={
                    user.permisos &&
                    user.permisos !== null &&
                    user.permisos.some((per) => per === 'PERADM01')
                        ? ''
                        : classes.displayNone
                }
            >
                <ModalNuevoUsuario refreshUsers={props.refreshUsers} />
            </div>
            <ModalEditarUsuario
                open={editarData.open}
                closeParent={editarData.closeParent}
                dataUser={editarData.dataEditUser}
            />
            <ModalConfirmaciones options={confirmaciones} />
            <ModalCambiarPassword options={chgPass} />
            <Snackbars options={snackbar} />
        </div>
    );
}
