import { ThemeProvider, Typography } from '@material-ui/core';
import { DetailTitleInfo } from '../../../interfaces';
import { useStyles } from '../courses-users.mat-ui';
import ProgressDetail from '../progress-user/progress-user.compt';
import './detail-title.sass';
import { modalTitleFontTheme } from '../../../styles-matui';

export default function DetailTitle(props: DetailTitleInfo) {
    const classes = useStyles();

    return (
        <section className='sectionHeader'>
            <article>
                <ThemeProvider theme={modalTitleFontTheme}>
                    <Typography
                        style={{ textTransform: 'uppercase' }}
                        gutterBottom
                        variant='h4'
                        component='h2'
                        color='primary'
                    >
                        {props.data.nombre}
                    </Typography>
                </ThemeProvider>

                <div className='divDescription'>
                    <div className='description'>
                        <Typography
                            className={classes.descriptionModule}
                            gutterBottom
                            variant='subtitle1'
                            component='p'
                        >
                            {props.data.descripcionLarga}
                        </Typography>
                    </div>

                    <img alt='img' src={props.data.foto}></img>
                </div>
            </article>

            <article>
                <ProgressDetail
                    data={{
                        progressSystem: props.data.progressSystem,
                        progressUser: props.data.progressUser,
                        calificacion: props.data.calificacion,
                    }}
                />
            </article>
        </section>
    );
}
