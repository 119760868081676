import { useState } from 'react';
import {
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    Typography,
    CardContent,
    CardActions,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { useStyles } from './card-nav.style-mat-ui';
import { useNavigate } from 'react-router-dom';
import { CardNav, CardNavData, DataControlModalParent } from '../../interfaces';
import { useDispatch } from 'react-redux';
import {
    moduleSelect,
    sesionSelect,
    courseSelect,
} from '../courses-users/courses-actions';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CircularProgressUser from '../courses-users/circular-progress-user/circular-progress-user.compt';
import { setDataCourse } from '../courses-users/courses-users.services';
import { STATUS } from '../../constants';
import Snackbars from '../snackbar/snackbar.compt';
import jsPDF from 'jspdf';
import certificado from '../../../src/assets/certificados/diploma.png';
import certificadoMod from '../../../src/assets/certificados/reconocimiento.png';
import '../../../src/assets/fuentes/code';
import '../../../src/assets/fuentes/constellation';
import './card-nav.sass';

export default function CardNavInfo(props: CardNav) {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });

    const goToNewComponent = (data: CardNavData) => {
        if (data.route) {
            navigate(data.route);
        } else if (data.idCurso) {
            dispatch({ type: courseSelect.type, course: data });

            if (
                data.modulos &&
                data.modulos !== null &&
                data.modulos.length > 0
            ) {
                setDataCourse(courseSelect.type, data);
                navigate(props.route);
            } else {
                openSnackBar(
                    `El curso no tiene modulos configurados aún.`,
                    STATUS.info,
                );
            }
        } else if (data.idModulo) {
            dispatch({ type: moduleSelect.type, module: data });

            if (
                data.sesiones &&
                data.sesiones !== null &&
                data.sesiones.length > 0
            ) {
                setDataCourse(moduleSelect.type, data);
                navigate(props.route);
            } else {
                openSnackBar(
                    `El modulo no tiene sesiones configuradas aún.`,
                    STATUS.info,
                );
            }
        } else if (data.idSesion) {
            dispatch({ type: sesionSelect.type, sesion: data });
            setDataCourse(sesionSelect.type, data);
            navigate(props.route);
        }
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const downloadDiploma = (value: any) => {
        const name = `pablo enriquez`;
        const doc = new jsPDF('l', 'mm', 'a4');
        if (value.idCurso) {
            const words = name.split(' ').length;
            const mult =
                words === 6
                    ? words + 2.5
                    : words === 5
                      ? words + 3.2
                      : words === 4
                        ? words + 3.4
                        : words === 3
                          ? words + 3.3
                          : words === 2
                            ? words + 2.8
                            : words + 4;
            doc.addImage(certificado, 'PNG', -1, 0, 299, 210);
            doc.setFont('constellation', 'normal');
            doc.setTextColor('#263680');
            doc.setFontSize(35);
            doc.text(name, 205 - 13 * mult, 107);
            doc.setFont('SourceCodePro-ExtraLight', 'normal');
            doc.setTextColor('#000000');
            doc.setFontSize(20);
            doc.text(new Date().toLocaleDateString(), 112, 177);
            doc.output('blob');
            doc.save(`${value.idCurso}.pdf`);
        } else if (value.idModulo) {
            doc.addImage(certificadoMod, 'PNG', -1, 0, 299, 210);
            const words = name.split(' ').length;
            const mult =
                words === 6
                    ? 4.8
                    : words === 5
                      ? words
                      : words === 4
                        ? words + 1.2
                        : words === 3
                          ? words + 1.7
                          : words === 2
                            ? words + 2.8
                            : words + 4;
            const ind = 298 / 2 - (mult * name.length) / 2;
            doc.setFont('constellation', 'normal');
            doc.setTextColor('#263680');
            doc.setFontSize(35);
            doc.text(name, ind, 96);
            doc.setFont('SourceCodePro-ExtraLight', 'normal');
            doc.setTextColor('#000000');
            doc.setFontSize(19);
            doc.text(new Date().toLocaleDateString(), 73, 185);
            doc.output('blob');
            doc.save(`${value.idModulo}.pdf`);
        }
    };

    return (
        <Grid
            container
            spacing={3}
            className={
                props.dashboardAdmin && props.dashboardAdmin === true
                    ? classes.rootDasboard
                    : classes.root
            }
        >
            {props.data.map((value: any, index) => (
                <Card
                    key={index}
                    className={
                        props.dashboardAdmin && props.dashboardAdmin === true
                            ? classes.rootCardDasboard
                            : classes.rootCard
                    }
                >
                    <CardActionArea
                        onClick={() => goToNewComponent(value)}
                        className={
                            props.dashboardAdmin &&
                            props.dashboardAdmin === true
                                ? classes.buttonRootCardDasboard
                                : ''
                        }
                    >
                        <CardMedia
                            className={
                                props.dashboardAdmin &&
                                props.dashboardAdmin === true
                                    ? classes.mediaRootCardDasboard
                                    : classes.media
                            }
                            image={value.foto}
                            title={value.nombre}
                        />
                        <CardContent
                            className={
                                props.dashboardAdmin &&
                                props.dashboardAdmin === true
                                    ? classes.cardContentRootCardDasboard
                                    : value.porcentaje === 0
                                      ? classes.cardContentSesion
                                      : classes.cardContent
                            }
                        >
                            <Typography
                                gutterBottom
                                variant='h5'
                                component='h2'
                                className='cardTitle'
                                color='primary'
                                style={
                                    props.dashboardAdmin &&
                                    props.dashboardAdmin === true
                                        ? { textAlign: 'center' }
                                        : {}
                                }
                            >
                                {value.nombre}
                            </Typography>
                            {props.dashboardAdmin !== true && (
                                <Typography
                                    variant='body2'
                                    component='p'
                                    className='cardDescription'
                                >
                                    {value.descripcionCorta}
                                </Typography>
                            )}
                        </CardContent>
                    </CardActionArea>

                    {props.dashboardAdmin !== true && (
                        <CardActions
                            className={
                                value.idSesion && value.idSesion !== undefined
                                    ? classes.cardActions2
                                    : value.banDiploma &&
                                        value.banDiploma === true
                                      ? classes.cardActions
                                      : classes.cardActions2
                            }
                        >
                            <div
                                className={
                                    value.idSesion &&
                                    value.idSesion !== undefined
                                        ? classes.displayNone
                                        : value.banDiploma &&
                                            value.banDiploma === true
                                          ? ''
                                          : classes.displayNone
                                }
                            >
                                <Tooltip
                                    title={
                                        value.idCurso
                                            ? 'Descargar cerificado'
                                            : 'Descargar diploma'
                                    }
                                >
                                    <IconButton
                                        className={classes.cardDownloadIcon}
                                        aria-label='dowload'
                                        onClick={() => downloadDiploma(value)}
                                    >
                                        <ArrowDownwardIcon
                                            color='primary'
                                            fontSize='inherit'
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <CircularProgressUser
                                data={{
                                    porcentaje: value.porcentaje,
                                }}
                            />
                        </CardActions>
                    )}
                </Card>
            ))}

            <Snackbars options={snackbar} />
        </Grid>
    );
}
