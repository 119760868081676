import React, { useState } from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {
    Usuario,
    EnhancedTableUserProps,
    Order,
    PropsEnhancedTableUsersToolbar,
    HeadCellUserCourse,
} from '../usuarios.interfaces';
import { useStyles, useToolbarStyles } from '../usuarios.styles-matui';
import { ButtonGroup, Button } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import ModalConfirmaciones from '../../modal-confirmaciones/modal-confirmaciones.compt';
import { DataControlModalParent } from '../../../interfaces';
import { STATUS } from '../../../constants';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import Spinner from '../../spinner/spinner.compt';
import { useGeneralStyles } from '../../../styles-matui';
import Snackbars from '../../snackbar/snackbar.compt';
import Avatar from '@material-ui/core/Avatar';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ProgressBarUser from '../../courses-users/progress-user/progress-bar-user';
import {
    INHABILITAR_USUARIO_CURSO,
    HABILITAR_USUARIO_CURSO,
    HABILITAR_CERTIFICADO,
    ELIMINAR_USUARIO_CURSO,
} from '../../administradores/administradores.graphql';
import { Reducers } from '../../../store';
import { Administrador } from '../../administradores/administradores.interfaces';

const HeadCellAdmins: HeadCellUserCourse[] = [
    {
        id: 'nombreCompleto',
        numeric: false,
        disablePadding: true,
        label: 'Nombre completo',
    },
    {
        id: 'banInactivo',
        numeric: false,
        disablePadding: false,
        label: 'Estatus',
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<Usuario>(
    array: Usuario[],
    comparator: (a: any, b: any) => number,
) {
    const stabilizedThis = array.map(
        (el, index) => [el, index] as [any, number],
    );
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props: EnhancedTableUserProps) => {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof Usuario) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>

                <TableCell align='left'>Foto</TableCell>

                {HeadCellAdmins.map((HeadCellAdmin) => (
                    <TableCell
                        key={HeadCellAdmin.id}
                        align='left'
                        sortDirection={
                            orderBy === HeadCellAdmin.id ? order : false
                        }
                    >
                        <TableSortLabel
                            active={orderBy === HeadCellAdmin.id}
                            direction={
                                orderBy === HeadCellAdmin.id ? order : 'asc'
                            }
                            onClick={createSortHandler(HeadCellAdmin.id)}
                        >
                            {HeadCellAdmin.label}
                            {orderBy === HeadCellAdmin.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

                <TableCell align='center' className={classes.cellAvance}>
                    Avance
                </TableCell>
                <TableCell align='center'>Acciones</TableCell>
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = (props: PropsEnhancedTableUsersToolbar) => {
    const classes = useToolbarStyles();
    const [delUsuarioCurso] = useMutation(ELIMINAR_USUARIO_CURSO);
    const [inhUsuarioCurso] = useMutation(INHABILITAR_USUARIO_CURSO);

    const inhabUser = (idUsuario: string, course: string) => {
        return new Promise((resolve, reject) => {
            inhUsuarioCurso({
                variables: {
                    data: {
                        idCurso: course,
                        idUsuario,
                    },
                },
            })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const deleteUser = (
        idUsuario: string,
        course: string,
    ): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            delUsuarioCurso({
                variables: {
                    data: {
                        idCurso: course,
                        idUsuario,
                    },
                },
            })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    const deleteAny = () => {
        const usersAllowed = props.usersSelected.filter(
            (user) =>
                !props.usuarios.find((item) => item.idUsuario === user).cursos
                    .banInactivo,
        );

        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay usuarios disponibles para eliminar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((user) =>
                deleteUser(user, props.course),
            );

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.refreshUsers('Exito al eliminar los usuarios');
                    props.setSelected([]);
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        'Error al eliminar los usuarios',
                        STATUS.error,
                    );
                });
        }
    };

    const inhabAny = () => {
        const usersAllowed = props.usersSelected.filter(
            (user) =>
                !props.usuarios.find((item) => item.idUsuario === user).cursos
                    .banInactivo,
        );

        if (usersAllowed.length === 0) {
            props.openSnackBar(
                `No hay usuarios disponibles para inhabilitar`,
                STATUS.error,
            );
        } else {
            props.setOpenSpinner(true);
            const promises = usersAllowed.map((user) =>
                inhabUser(user, props.course),
            );

            Promise.all(promises)
                .then(() => {
                    props.setOpenSpinner(false);
                    props.refreshUsers('Exito al inhabilitar los usuarios');
                    props.setSelected([]);
                })
                .catch(() => {
                    props.setOpenSpinner(false);
                    props.openSnackBar(
                        `Error al inhabilitar los usuarios`,
                        STATUS.error,
                    );
                });
        }
    };

    return (
        <Toolbar className={classes.root}>
            <Typography
                className={classes.title}
                variant='h6'
                id='tableTitle'
                component='div'
                color='primary'
            >
                Lista de usuarios por curso
            </Typography>

            <ButtonGroup
                className={classes.todosButtonGroup}
                color='primary'
                aria-label='outlined primary button group'
            >
                <Button
                    onClick={inhabAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.usersSelected.length === 0,
                    })}
                >
                    <BlockIcon />
                </Button>
                <Button
                    onClick={deleteAny}
                    className={clsx(classes.todosActionButtons, {
                        [classes.todosActionButtonsOcult]:
                            props.usersSelected.length === 0,
                    })}
                >
                    <DeleteIcon />
                </Button>
            </ButtonGroup>
        </Toolbar>
    );
};

export default function UsuarioCurso(props) {
    const classes = useStyles();
    const generalClasses = useGeneralStyles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Usuario>('nombreCompleto');
    const [selected, setSelected] = useState<string[]>([]);
    const [habCertificado] = useMutation(HABILITAR_CERTIFICADO);
    const [delUsuarioCurso] = useMutation(ELIMINAR_USUARIO_CURSO);
    const [inhUsuarioCurso] = useMutation(INHABILITAR_USUARIO_CURSO);
    const [habUsuarioCurso] = useMutation(HABILITAR_USUARIO_CURSO);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [confirmaciones, setConfirmaciones] =
        useState<DataControlModalParent>({ open: false, descripcion: '' });
    const adminUser = useSelector<Reducers>(
        (reducers) => reducers.loginReducer,
    ) as Administrador;
    const usersByCourse = props.usuarios
        .filter(
            (usr) =>
                usr.cursos &&
                usr.cursos.find((cur) => cur.idCurso === props.course) &&
                usr.banInactivo !== true,
        )
        .map((usr) => {
            return {
                ...usr,
                cursos: Object.assign(
                    usr.cursos.find((cur) => cur.idCurso === props.course) ??
                        {},
                    adminUser.cursos
                        ? { ...adminUser }.cursos.find(
                              (cur) => cur.idCurso === props.course,
                          )
                        : {},
                ),
            };
        }) as Usuario[];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Usuario,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            const newSelecteds = usersByCourse.map(
                (n) => n.idUsuario,
            ) as string[];
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (
        event: React.MouseEvent<unknown>,
        idUsuario: string,
    ) => {
        const selectedIndex = selected.indexOf(idUsuario);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, idUsuario);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const closeModalConfirmaciones = (
        value: boolean,
        accion: string,
        info: any,
    ) => {
        setConfirmaciones({ open: false, descripcion: '' });
        const userSelect = usersByCourse.find(
            (user: Usuario) => user.idUsuario === info.uid,
        ) as Usuario;

        if (value) {
            setOpenSpinner(true);
        }

        if (value && accion === 'certificado') {
            habCertificado({
                variables: {
                    data: {
                        idCurso: info.course,
                        idUsuario: info.uid,
                    },
                },
            })
                .then(() => {
                    props.refreshUsers('Exito al habilitar certificado');
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        'Error al habilitar certificado',
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'inhabilitar') {
            inhUsuarioCurso({
                variables: {
                    data: {
                        idCurso: info.course,
                        idUsuario: info.uid,
                    },
                },
            })
                .then(() => {
                    props.refreshUsers(
                        `Exito al inhabilitar al usuario ${userSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al inhabilitar al usuario ${userSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'habilitar') {
            habUsuarioCurso({
                variables: {
                    data: {
                        idCurso: info.course,
                        idUsuario: info.uid,
                    },
                },
            })
                .then(() => {
                    props.refreshUsers(
                        `Exito al habilitar al usuario ${userSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al habilitar al usuario ${userSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        } else if (value && accion === 'eliminar') {
            delUsuarioCurso({
                variables: {
                    data: {
                        idCurso: info.course,
                        idUsuario: info.uid,
                    },
                },
            })
                .then(() => {
                    props.refreshUsers(
                        `Exito al eliminar al usuario ${userSelect.nombreCompleto}`,
                    );
                    setOpenSpinner(false);
                })
                .catch(() => {
                    openSnackBar(
                        `Error al eliminar al usuario ${userSelect.nombreCompleto}`,
                        STATUS.error,
                    );
                    setOpenSpinner(false);
                });
        }
    };

    const handleModalConfirmacionesInhabilitar = (usuario: any) => {
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de ${
                usuario.cursos.banInactivo &&
                usuario.cursos.banInactivo !== null &&
                usuario.cursos.banInactivo
                    ? 'habilitar'
                    : 'inhabilitar'
            } a ${usuario.nombreCompleto}?`,
            closeParent: closeModalConfirmaciones,
            accion:
                usuario.cursos.banInactivo &&
                usuario.cursos.banInactivo !== null &&
                usuario.cursos.banInactivo
                    ? 'habilitar'
                    : 'inhabilitar',
            uid: usuario.idUsuario,
            course: usuario.cursos.idCurso,
        });
    };

    const handleModalConfirmacionesEliminar = (usuario: any) => {
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de eliminar a ${usuario.nombreCompleto}?`,
            closeParent: closeModalConfirmaciones,
            accion: 'eliminar',
            uid: usuario.idUsuario,
            course: usuario.cursos.idCurso,
        });
    };

    const handleModalHabilitarCertificado = (usuario: any) => {
        setConfirmaciones({
            open: true,
            descripcion: `¿Estas seguro de habilitar el certificado para ${usuario.nombreCompleto}?`,
            closeParent: closeModalConfirmaciones,
            accion: 'certificado',
            uid: usuario.idUsuario,
            course: usuario.cursos.idCurso,
        });
    };

    const isSelected = (idUsuario: string) =>
        selected.indexOf(idUsuario) !== -1;

    /**
     * Get the progress of system, this number will calculate
     * with the number of days transcurred since the register date.
     */
    const getProgressSystem = (fechaAlta: string): number => {
        const fechaInicio = new Date(Number(fechaAlta));
        const today = new Date();
        const diffDays = Math.round(
            (today.getTime() - fechaInicio.getTime()) / 24 / 1000 / 60 / 60,
        );

        // If diff days lower than 100 then it is mean user is in time
        // then course time for user is end.
        if (diffDays < 100) {
            const progress = (diffDays * 100) / 70;
            return Math.round(progress);
        }

        return 100;
    };

    return (
        <div className={classes.root}>
            <div
                className={
                    usersByCourse.length > 0
                        ? classes.displayNone
                        : classes.titleNoAdmins
                }
            >
                <Typography component='h4'>
                    <b>No hay usuarios disponibles para visualizar</b>
                </Typography>
            </div>
            <Paper
                className={
                    usersByCourse.length === 0
                        ? classes.displayNone
                        : classes.paper
                }
            >
                <EnhancedTableToolbar
                    course={props.course}
                    setSelected={setSelected}
                    usuarios={usersByCourse}
                    usersSelected={selected}
                    openSnackBar={openSnackBar}
                    setOpenSpinner={setOpenSpinner}
                    refreshUsers={props.refreshUsers}
                />

                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size='medium'
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={usersByCourse.length}
                        />

                        <TableBody>
                            {stableSort(
                                usersByCourse,
                                getComparator(order, orderBy),
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row, index) => {
                                    const isItemSelected = isSelected(
                                        row.idUsuario,
                                    );
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            className={
                                                row.cursos.banInactivo &&
                                                row.cursos.banInactivo !==
                                                    null &&
                                                row.cursos.banInactivo
                                                    ? generalClasses.tableRowDisable
                                                    : ''
                                            }
                                            hover
                                            role='checkbox'
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.idUsuario}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding='checkbox'>
                                                <Checkbox
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.idUsuario,
                                                        )
                                                    }
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            labelId,
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell align='center'>
                                                <Avatar
                                                    alt={row.nombreCompleto}
                                                    src={row.foto}
                                                />
                                            </TableCell>

                                            <TableCell
                                                component='th'
                                                id={labelId}
                                                scope='row'
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idUsuario,
                                                    )
                                                }
                                            >
                                                {row.nombreCompleto}
                                            </TableCell>

                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idUsuario,
                                                    )
                                                }
                                                align='left'
                                            >
                                                <b>
                                                    {row.cursos.banInactivo &&
                                                    row.cursos.banInactivo !==
                                                        null &&
                                                    row.cursos.banInactivo
                                                        ? 'Inactivo'
                                                        : 'Activo'}
                                                </b>
                                            </TableCell>

                                            <TableCell
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        row.idUsuario,
                                                    )
                                                }
                                                align='left'
                                            >
                                                <ProgressBarUser
                                                    data={{
                                                        progressSystem:
                                                            row.cursos &&
                                                            row.cursos
                                                                .fechaAlta &&
                                                            row.cursos
                                                                .fechaAlta !==
                                                                null
                                                                ? getProgressSystem(
                                                                      row.cursos
                                                                          .fechaAlta,
                                                                  )
                                                                : 0,
                                                        progressUser:
                                                            row.cursos
                                                                .porcentaje ===
                                                            null
                                                                ? 0
                                                                : row.cursos
                                                                      .porcentaje,
                                                    }}
                                                />
                                            </TableCell>

                                            <TableCell align='center'>
                                                <ButtonGroup
                                                    color='primary'
                                                    aria-label='outlined primary button group'
                                                >
                                                    <Button
                                                        disabled={
                                                            row.cursos
                                                                .permisos &&
                                                            row.cursos
                                                                .permisos !==
                                                                null &&
                                                            row.cursos
                                                                .porcentaje !==
                                                                null
                                                                ? row.cursos
                                                                      .banInactivo &&
                                                                  row.cursos
                                                                      .banInactivo !==
                                                                      null
                                                                    ? row.cursos
                                                                          .banInactivo ||
                                                                      !row.cursos.permisos.some(
                                                                          (
                                                                              per,
                                                                          ) =>
                                                                              per ===
                                                                              'PERADMCUR05',
                                                                      ) ||
                                                                      row.cursos
                                                                          .porcentaje <
                                                                          85
                                                                    : !row.cursos.permisos.some(
                                                                          (
                                                                              per,
                                                                          ) =>
                                                                              per ===
                                                                              'PERADMCUR05',
                                                                      ) ||
                                                                      row.cursos
                                                                          .porcentaje <
                                                                          85
                                                                : true
                                                        }
                                                        id='btnEditar'
                                                        onClick={() =>
                                                            handleModalHabilitarCertificado(
                                                                row,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Habilitar descarga de certificado'>
                                                            <CardMembershipIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            row.cursos
                                                                .permisos &&
                                                            row.cursos
                                                                .permisos !==
                                                                null
                                                                ? !row.cursos.permisos.some(
                                                                      (per) =>
                                                                          per ===
                                                                          'PERADMCUR04',
                                                                  )
                                                                : true
                                                        }
                                                        id='btnInhabilitar'
                                                        onClick={() =>
                                                            handleModalConfirmacionesInhabilitar(
                                                                row,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip
                                                            title={
                                                                row.cursos
                                                                    .banInactivo &&
                                                                row.cursos
                                                                    .banInactivo !==
                                                                    null &&
                                                                row.cursos
                                                                    .banInactivo
                                                                    ? 'Habilitar usuario al curso'
                                                                    : 'Inhabilitar usuario del curso'
                                                            }
                                                        >
                                                            <BlockIcon />
                                                        </Tooltip>
                                                    </Button>

                                                    <Button
                                                        disabled={
                                                            row.cursos
                                                                .permisos &&
                                                            row.cursos
                                                                .permisos !==
                                                                null
                                                                ? row.cursos
                                                                      .banInactivo &&
                                                                  row.cursos
                                                                      .banInactivo !==
                                                                      null
                                                                    ? row.cursos
                                                                          .banInactivo ||
                                                                      !row.cursos.permisos.some(
                                                                          (
                                                                              per,
                                                                          ) =>
                                                                              per ===
                                                                              'PERADMCUR04',
                                                                      )
                                                                    : !row.cursos.permisos.some(
                                                                          (
                                                                              per,
                                                                          ) =>
                                                                              per ===
                                                                              'PERADMCUR04',
                                                                      )
                                                                : true
                                                        }
                                                        id='btnEliminar'
                                                        onClick={() =>
                                                            handleModalConfirmacionesEliminar(
                                                                row,
                                                            )
                                                        }
                                                        className={
                                                            classes.actionButtons
                                                        }
                                                    >
                                                        <Tooltip title='Eliminar usuario del curso'>
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                    </Button>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    labelRowsPerPage='Resultados por página'
                    count={usersByCourse.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />
            <ModalConfirmaciones options={confirmaciones} />
            <Snackbars options={snackbar} />
        </div>
    );
}
