import * as XLSX from 'xlsx';
import React, { useState, useEffect, useRef } from 'react';
import Regresar from '../regresar/regresar.compt';
import {
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Avatar,
    LinearProgress,
    Paper,
    ThemeProvider,
} from '@material-ui/core';
import { useStyles } from './reportes.mat-ui';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_USERS_REPORTS } from '../usuarios/usuarios.graphql';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { GET_ALL_COURSES_REPORTS } from '../cursos/cursos.graphql';
import { VACIO, COLUMNAS_TABLA, MESES, STATUS } from '../../constants';
import logo from '../../../src/assets/img/ococlimas-logo.png';
import ProgressBarUser from '../courses-users/progress-user/progress-bar-user';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgressUser from '../courses-users/circular-progress-user/circular-progress-user.compt';
import { Curso, NewModulo } from '../cursos/cursos.interfaces';
import Snackbars from '../snackbar/snackbar.compt';
import { DataControlModalParent } from '../../interfaces';
import './reportes.sass';
import { UserVideo, Usuario } from '../usuarios/usuarios.interfaces';
import { themeButtons } from '../../theme.styles';

export default function Reportes() {
    const classes = useStyles();
    const startYear = useRef<number>(2021);
    const [buscador, setBuscador] = useState('general');
    const [cursoSelect, setCursoSelect] = useState('selecuno');
    const [mesSelect, setMesSelect] = useState(MESES[0]);
    const [yearsOptions, setYearsOptions] = useState<Array<string>>(['Todos']);
    const [yearSelected, setYearSelected] = useState<string>('Todos');
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const queryUsers = useQuery(GET_ALL_USERS_REPORTS);
    const queryCourses = useQuery(GET_ALL_COURSES_REPORTS);
    const [userCourses, setUserCourses] = useState<Curso[]>([]);
    const [usuarios, setUsuarios] = useState<Usuario[]>([]);
    const cursos = useRef<Curso[]>([]);

    useEffect(() => {
        if (queryCourses.data?.getCursos) {
            cursos.current = queryCourses.data.getCursos;
            setUserCourses(queryCourses.data.getCursos);
        }

        if (queryUsers.data?.getUsers) {
            setUsuarios(queryUsers.data.getUsers);
        }
    }, [queryUsers.data, queryCourses.data]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = [] as Array<string>;
        for (let year = startYear.current; year <= currentYear; year++) {
            years.push(year.toString());
        }
        setYearsOptions((prev) => [...prev, ...years]);
    }, [startYear.current]);

    const handleChangeBuscador = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        const buscador = event.target.value as string;
        if (buscador !== 'general') {
            const usuario = usuarios.find(
                (user) => user.idUsuario === buscador,
            ) as Usuario;
            setUserCourses(
                cursos.current.filter((curso) =>
                    usuario.cursos?.find(
                        (cur) => cur.idCurso === curso.idCurso,
                    ),
                ),
            );
            setCursoSelect('selecuno');
        } else {
            setUserCourses(cursos.current);
            setCursoSelect('selecuno');
        }

        setBuscador(buscador);
    };

    const handleChangeCurso = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        const curso = event.target.value as string;
        setCursoSelect(curso);
        setYearSelected('Todos');
        setMesSelect('Ninguno');
    };

    const handleChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
        const year = event.target.value as string;
        setYearSelected(year);
        setMesSelect('Ninguno');
    };

    const handleChangeMes = (event: React.ChangeEvent<{ value: unknown }>) => {
        const mes = event.target.value as string;
        setMesSelect(mes);
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const generarReporte = () => {
        try {
            const doc = new jsPDF('l', 'mm', 'a4');
            const diasAvance = (porcentaje: number) => {
                if (porcentaje === null || porcentaje === undefined) {
                    return 0;
                }

                return Math.round((porcentaje * 70) / 100);
            };
            if (buscador !== 'general') {
                window.print();
            } else if (buscador === 'general' && cursoSelect !== 'selecuno') {
                const data = usuarios.filter(
                    (usr) =>
                        usr.cursos &&
                        usr.cursos !== null &&
                        usr.cursos.find((cur) => cur.idCurso === cursoSelect),
                );

                let newData = data
                    .map((dat) => {
                        return {
                            ...dat,
                            cursos: {
                                ...dat.cursos.find(
                                    (cur) => cur.idCurso === cursoSelect,
                                ),
                                avance: diasAvance(
                                    (
                                        dat.cursos.find(
                                            (cur) =>
                                                cur.idCurso === cursoSelect,
                                        ) as Curso
                                    ).porcentaje,
                                ),
                                diasTranscurridos: Math.round(
                                    (new Date().getTime() -
                                        new Date(
                                            Number(
                                                (
                                                    dat.cursos.find(
                                                        (cur) =>
                                                            cur.idCurso ===
                                                            cursoSelect,
                                                    ) as Curso
                                                ).fechaAlta,
                                            ),
                                        ).getTime()) /
                                        (1000 * 60 * 60 * 24),
                                ),
                            },
                        };
                    })
                    .filter(
                        (item) =>
                            item.cursos.modulos && item.cursos.modulos !== null,
                    );

                const currentCourse: any = userCourses.find(
                    (cur) => cur.idCurso === cursoSelect,
                );
                newData = newData.map((user) => {
                    return {
                        ...user,
                        cursos: {
                            ...user.cursos,
                            modulos: currentCourse.modulos.map((modulo) => {
                                const modules = user.cursos
                                    .modulos as NewModulo[];
                                const moduleFound = modules.find(
                                    (modUsr) =>
                                        modulo.idModulo === modUsr.idModulo,
                                );
                                return moduleFound
                                    ? {
                                          ...modules.find(
                                              (modUsr) =>
                                                  modulo.idModulo ===
                                                  modUsr.idModulo,
                                          ),
                                          nombre: modulo.nombre,
                                      }
                                    : {
                                          banDiploma: false,
                                          fechaAlta: VACIO,
                                          fechaTermino: VACIO,
                                          idModulo: modulo.idModulo,
                                          porcentaje: 0,
                                          nombre: modulo.nombre,
                                      };
                            }),
                        },
                    };
                });
                if (yearSelected !== 'Todos') {
                    newData = newData.filter((user) => {
                        const yearInit = new Date(
                            Number(user.cursos.fechaAlta),
                        ).getFullYear();
                        if (
                            yearInit === Number(yearSelected) &&
                            (user.cursos.modulos as NewModulo[]).length > 0
                        ) {
                            return user;
                        }
                    });
                }

                if (mesSelect !== MESES[0]) {
                    newData = newData.filter((user) => {
                        const selectedMonth =
                            MESES.findIndex((mes) => mes === mesSelect) - 1;
                        const mesInit = new Date(
                            Number(user.cursos.fechaAlta),
                        ).getMonth();

                        if (
                            mesInit === selectedMonth &&
                            (user.cursos.modulos as NewModulo[]).length > 0
                        ) {
                            return user;
                        }
                    });
                }

                if (
                    newData.some(
                        (user) =>
                            (user.cursos.modulos as NewModulo[]).length > 0,
                    )
                ) {
                    const HEADERS_TABLE = [
                        { ...COLUMNAS_TABLA, content: 'Nombre' },
                        ...(newData[0].cursos.modulos as NewModulo[]).map(
                            (item) => ({
                                ...COLUMNAS_TABLA,
                                content: 'Modulo: ' + item.nombre,
                            }),
                        ),
                    ];
                    const courseName = userCourses.find(
                        (cur) => cur.idCurso === cursoSelect,
                    )?.nombre;
                    const maximize =
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAJUlEQVRIiWNgGAWjYBSMAoKAEYvYf2qayUShYaNgFIyCUUANAADwGQEEgjOwlAAAAABJRU5ErkJggg==';
                    const error =
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAgklEQVRIie2UMQ6AIAxFX7wDiUdwwPtPxDvI4HF0KQkxhlChi/Inht//Sz8UBn6LBdiAuYLrgACsGoMAnEAUgZJ4FG7QGDhgl8KD55vUcF6bNIuXhLqJ5yZpzvF2LuWjNkldqzqfenXQAtMRmYZs+kzNP5r5qvBSUBNiWnZeYzDwIVwgcD9FngsRrwAAAABJRU5ErkJggg==';
                    const done =
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAiElEQVRIie3RMQrCQBBG4U+DeBXvYpdGb2Jp7HIWwUNYeCZDELTZoBFUEner7CtnmTc7/5DJDGSVUr5Gg/q1OI8oP2GJayRnT97gjn1seYl2WvIFjtimkMMmNLZB9E1eDZV37ILgpr9J1MzfhyQ5aOUZ19+xfKLbZPTPix/vF8xwxmHMgMwEeADr0i1iN/+DAgAAAABJRU5ErkJggg==';
                    doc.addImage(logo, 'JPG', 14, 5, 35, 30);
                    doc.text(new Date().toLocaleDateString(), 258, 42);
                    doc.text(
                        `Lista de usuarios del curso: "${courseName}"`,
                        14,
                        44,
                    );
                    doc.addImage(maximize, 'JPG', 14, 46, 5, 5);
                    doc.setFontSize(10);
                    doc.text('No ha acompletado el modulo', 21, 50);
                    doc.addImage(error, 'JPG', 14, 51, 5, 5);
                    doc.text('No ha presentado avances', 21, 55);
                    doc.addImage(done, 'JPG', 14, 56, 5, 5);
                    doc.text('Acompleto el modulo', 21, 60);
                    autoTable(doc, {
                        startY: 65,
                        head: [HEADERS_TABLE],
                        body: newData.map((user, ind) =>
                            HEADERS_TABLE.map((itm, pos) =>
                                pos === 0
                                    ? `${ind + 1}.- ${user.nombreCompleto}`
                                    : VACIO,
                            ),
                        ),
                        didDrawCell: (data) => {
                            if (data.section === 'head') {
                                const { cell, doc } = data;
                                doc.setFillColor('#263680');
                                doc.rect(
                                    cell.x,
                                    cell.y,
                                    cell.width,
                                    cell.height,
                                    'F',
                                );
                                doc.setTextColor(255, 255, 255);
                                doc.text(
                                    cell.text,
                                    cell.x + cell.padding('top'),
                                    cell.y + cell.height / 2,
                                    {
                                        baseline: 'middle',
                                    },
                                );
                            } else if (
                                data.section === 'body' &&
                                data.column.index !== 0
                            ) {
                                const cursos = newData[data.row.index]?.cursos;
                                if (cursos) {
                                    const modules =
                                        cursos?.modulos as NewModulo[];
                                    doc.addImage(
                                        modules[data.column.index - 1]
                                            ?.porcentaje === 0
                                            ? error
                                            : (modules[data.column.index - 1]
                                                    .porcentaje as number) >= 85
                                              ? done
                                              : maximize,
                                        'JPEG',
                                        data.cell.x + 4,
                                        data.cell.y + 1,
                                        5,
                                        5,
                                    );
                                } else {
                                    doc.addImage(
                                        maximize,
                                        'JPEG',
                                        data.cell.x + 4,
                                        data.cell.y + 1,
                                        5,
                                        5,
                                    );
                                }
                            }
                        },
                    });

                    doc.save(
                        `${courseName}${
                            yearSelected !== 'Todos' ? '-' + yearSelected : ''
                        }${mesSelect !== MESES[0] ? '-' + mesSelect : ''}.pdf`,
                    );
                } else {
                    openSnackBar(
                        'No hay modulos para generar un reporte',
                        STATUS.warning,
                    );
                }
            } else if (buscador === 'general') {
                const excelReport = usuarios.map((user) => {
                    if (!user.cursos) {
                        return {
                            cve_age: user.idDashboard,
                            Nombre: user.nombreCompleto,
                            'Número de cursos': 0,
                            'Cursos concluídos': 0,
                            'Tiempo invertido': '0.00 minutos',
                        };
                    }

                    const coursesDone = user.cursos?.filter(
                        (course) => course.porcentaje >= 80,
                    );
                    let totalSecondsInvested = 0;
                    user.cursos.forEach((course) => {
                        if (course.modulos) {
                            course.modulos.forEach((courseModule) => {
                                if (courseModule.sesiones) {
                                    courseModule.sesiones.forEach(
                                        (moduleSesion) => {
                                            if (moduleSesion.videos) {
                                                moduleSesion.videos.forEach(
                                                    (sesionVideo) => {
                                                        const userVideo =
                                                            sesionVideo as UserVideo;
                                                        totalSecondsInvested =
                                                            totalSecondsInvested +
                                                            Number(
                                                                userVideo?.currentTime ||
                                                                    0,
                                                            );
                                                    },
                                                );
                                            }
                                        },
                                    );
                                }
                            });
                        }
                    });
                    const totalMinutes = totalSecondsInvested / 60;
                    return {
                        cve_age: user.idDashboard,
                        Nombre: user.nombreCompleto,
                        'Número de cursos': user.cursos.length,
                        'Cursos concluídos': coursesDone.length,
                        'Tiempo invertido': `${
                            totalMinutes >= 60
                                ? (totalMinutes / 60).toFixed(2)
                                : totalMinutes.toFixed(2)
                        } ${totalMinutes >= 60 ? 'horas' : 'minutos'}`,
                    };
                });
                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(excelReport);
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja 1');
                const today = new Date();
                XLSX.writeFile(
                    workbook,
                    `Informe_usuarios-${today.toLocaleDateString()}.xlsx`,
                );
            }
        } catch (error) {
            openSnackBar(
                'Un error ha ocurrido al intentar generar tu reporte',
                STATUS.error,
            );
        }
    };

    if (queryUsers.loading || queryCourses.loading) {
        return <LinearProgress color='secondary' />;
    }

    if (queryUsers.error || queryCourses.error) {
        openSnackBar('No se ha podido cargar el contenido', STATUS.error);
    }

    return (
        <div>
            <Regresar route='/ccap/admin/menu' />
            <Typography
                variant='h6'
                id='tableTitle'
                component='div'
                className={classes.title}
                color='primary'
            >
                Generar reportes
            </Typography>

            <div>
                <FormControl variant='outlined' className={classes.comboCursos}>
                    <InputLabel id='demo-simple-select-outlined-label'>
                        Buscar
                    </InputLabel>
                    <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        value={buscador}
                        onChange={handleChangeBuscador}
                        label='Opción'
                    >
                        <MenuItem
                            key='general'
                            className={classes.menuItem}
                            value='general'
                        >
                            General
                        </MenuItem>
                        {usuarios
                            .filter((user) => !user.banInactivo)
                            .map((usuario, index) => (
                                <MenuItem
                                    key={index}
                                    className={classes.menuItem}
                                    value={usuario.idUsuario}
                                >
                                    {usuario.nombreCompleto}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <FormControl variant='outlined' className={classes.comboCursos}>
                    <InputLabel id='demo-simple-select-outlined-label'>
                        Curso
                    </InputLabel>
                    <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        value={cursoSelect}
                        onChange={handleChangeCurso}
                        label='Opción'
                    >
                        <MenuItem
                            key='todos'
                            className={
                                buscador === 'general'
                                    ? classes.menuItem
                                    : classes.displayNone
                            }
                            value='selecuno'
                        >
                            Selecciona uno
                        </MenuItem>

                        <MenuItem
                            key='selecuno'
                            className={
                                buscador !== 'general'
                                    ? classes.menuItem
                                    : classes.displayNone
                            }
                            value='selecuno'
                        >
                            Selecciona uno
                        </MenuItem>
                        {userCourses.map((curso, index) => (
                            <MenuItem
                                key={index}
                                className={classes.menuItem}
                                value={curso.idCurso}
                            >
                                {curso.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl
                    variant='outlined'
                    className={
                        buscador === 'general' && cursoSelect !== 'selecuno'
                            ? classes.comboCursos
                            : classes.displayNone
                    }
                >
                    <InputLabel id='year-select-outlined-label'>Año</InputLabel>
                    <Select
                        labelId='year-select-outlined-label'
                        id='year-select-outlined'
                        value={yearSelected}
                        onChange={handleChangeYear}
                        label='Opción'
                    >
                        {yearsOptions.map((year) => (
                            <MenuItem
                                key={year}
                                className={classes.menuItem}
                                value={year}
                            >
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl
                    variant='outlined'
                    className={
                        yearSelected !== 'Todos'
                            ? classes.formControl
                            : classes.displayNone
                    }
                >
                    <InputLabel id='demo-simple-select-outlined-label'>
                        Mes
                    </InputLabel>
                    <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        value={mesSelect}
                        onChange={handleChangeMes}
                        label='Opción'
                    >
                        {MESES.map((mes) => (
                            <MenuItem
                                key={mes}
                                className={classes.menuItem}
                                value={mes}
                            >
                                {mes}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {buscador !== 'general' && cursoSelect !== 'selecuno' ? (
                <div id='container' className='containerReport'>
                    <Paper className={classes.paperUser}>
                        <div>
                            <Typography
                                variant='h6'
                                component='h6'
                                className='containerTitleUser'
                            >
                                Información de usuario
                            </Typography>
                        </div>

                        <div className='containerInfoUser'>
                            <div className='infoUser1'>
                                <Avatar
                                    className={classes.avatar}
                                    src={
                                        (
                                            usuarios.find(
                                                (usr) =>
                                                    usr.idUsuario === buscador,
                                            ) as Usuario
                                        ).foto
                                    }
                                />
                            </div>

                            <div className='infoUser2'>
                                <Typography variant='subtitle1' component='div'>
                                    Nombre:{' '}
                                    <b>
                                        {
                                            (
                                                usuarios.find(
                                                    (usr) =>
                                                        usr.idUsuario ===
                                                        buscador,
                                                ) as Usuario
                                            ).nombreCompleto
                                        }
                                    </b>
                                </Typography>

                                <Typography variant='subtitle1' component='div'>
                                    Correo:{' '}
                                    <b>
                                        {
                                            (
                                                usuarios.find(
                                                    (usr) =>
                                                        usr.idUsuario ===
                                                        buscador,
                                                ) as Usuario
                                            ).correo
                                        }
                                    </b>
                                </Typography>

                                <Typography variant='subtitle1' component='div'>
                                    Celular:{' '}
                                    <b>
                                        {
                                            (
                                                usuarios.find(
                                                    (usr) =>
                                                        usr.idUsuario ===
                                                        buscador,
                                                ) as Usuario
                                            ).celular
                                        }
                                    </b>
                                </Typography>

                                <Typography variant='subtitle1' component='div'>
                                    Fecha de inicio del curso:{' '}
                                    <b>
                                        {new Date(
                                            Number(
                                                (
                                                    usuarios.find(
                                                        (usr) =>
                                                            usr.idUsuario ===
                                                                buscador &&
                                                            usr.cursos,
                                                    ) as Usuario
                                                ).cursos.filter(
                                                    (curs) =>
                                                        curs.idCurso ===
                                                        cursoSelect,
                                                )[0].fechaAlta as string,
                                            ),
                                        ).toLocaleDateString()}
                                    </b>
                                </Typography>
                            </div>
                        </div>
                    </Paper>
                    {(
                        usuarios.find(
                            (usr) => usr.idUsuario === buscador,
                        ) as Usuario
                    ).cursos
                        .filter((curs) => curs.idCurso === cursoSelect)
                        .reduce(
                            (init: Array<NewModulo>, curso) =>
                                curso.modulos && curso.modulos !== null
                                    ? [...curso.modulos]
                                    : init,
                            [],
                        )
                        .map((modulo, pos) => (
                            <Paper key={modulo.idModulo} className='child'>
                                <div className='childHeader'>
                                    <div className='progressHeader'>
                                        <ProgressBarUser
                                            data={{
                                                progressSystem: 40,
                                                progressUser:
                                                    modulo.porcentaje as number,
                                            }}
                                        />
                                    </div>
                                    <Typography
                                        variant='subtitle1'
                                        component='div'
                                        className={classes.titleModulo}
                                    >
                                        <b>
                                            {userCourses
                                                .find(
                                                    (cur) =>
                                                        cur.idCurso ===
                                                        cursoSelect,
                                                )
                                                ?.modulos.find(
                                                    (mod) =>
                                                        mod.idModulo ===
                                                        modulo.idModulo,
                                                )
                                                ?.nombre.toUpperCase()}
                                        </b>
                                    </Typography>
                                </div>

                                <div className='dataChild'>
                                    {modulo.sesiones &&
                                    modulo.sesiones !== null ? (
                                        modulo.sesiones.map((sesion, index) => (
                                            <Paper
                                                key={sesion.idSesion}
                                                className='dataSesion'
                                            >
                                                <Typography
                                                    variant='subtitle1'
                                                    component='div'
                                                    className={
                                                        classes.titleSesion
                                                    }
                                                >
                                                    {
                                                        userCourses
                                                            .find(
                                                                (cur) =>
                                                                    cur.idCurso ===
                                                                    cursoSelect,
                                                            )
                                                            ?.modulos.find(
                                                                (mod) =>
                                                                    mod.idModulo ===
                                                                    modulo.idModulo,
                                                            )
                                                            ?.sesiones.find(
                                                                (ses) =>
                                                                    ses.idSesion ===
                                                                    sesion.idSesion,
                                                            )?.nombre
                                                    }
                                                </Typography>

                                                <Typography
                                                    variant='subtitle1'
                                                    component='div'
                                                    className={
                                                        classes.dataSesion
                                                    }
                                                >
                                                    {sesion.examen &&
                                                    sesion.examen !== null &&
                                                    sesion.examen.sort(
                                                        (a, b) =>
                                                            b.calificacion -
                                                            a.calificacion,
                                                    )[0].calificacion >= 85 ? (
                                                        <DoneIcon
                                                            className={
                                                                classes.iconDoneDataSesion
                                                            }
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            className={
                                                                classes.iconErrorDataSesion
                                                            }
                                                        />
                                                    )}{' '}
                                                    Calificación:{' '}
                                                    <b>
                                                        {sesion.examen &&
                                                        sesion.examen !== null
                                                            ? sesion.examen.sort(
                                                                  (a, b) =>
                                                                      b.calificacion -
                                                                      a.calificacion,
                                                              )[0].calificacion
                                                            : 0}
                                                    </b>
                                                </Typography>

                                                <Typography
                                                    variant='subtitle1'
                                                    component='div'
                                                    className={
                                                        classes.dataSesion
                                                    }
                                                >
                                                    <EditIcon
                                                        className={
                                                            classes.iconDataSesion
                                                        }
                                                    />
                                                    Intentos de examen:{' '}
                                                    <b>
                                                        {sesion.examen &&
                                                        sesion.examen !== null
                                                            ? sesion.examen
                                                                  .length
                                                            : 0}
                                                    </b>
                                                </Typography>

                                                <div className='containerProgressSesion'>
                                                    <CircularProgressUser
                                                        data={{
                                                            porcentaje:
                                                                sesion.porcentaje as number,
                                                        }}
                                                    />
                                                </div>
                                            </Paper>
                                        ))
                                    ) : (
                                        <Typography
                                            color='error'
                                            variant='subtitle1'
                                            component='div'
                                        >
                                            <b>
                                                No hay información de sesiones
                                                aún.
                                            </b>
                                        </Typography>
                                    )}
                                </div>
                            </Paper>
                        ))}
                </div>
            ) : (
                <label></label>
            )}

            <div className='containerBtnReporte'>
                <ThemeProvider theme={themeButtons}>
                    <Button
                        disabled={
                            cursoSelect === 'selecuno' && buscador !== 'general'
                        }
                        variant='contained'
                        color='primary'
                        disableElevation
                        onClick={generarReporte}
                    >
                        {buscador === 'general'
                            ? 'Descargar reporte'
                            : 'Imprimir reporte'}
                    </Button>
                </ThemeProvider>
            </div>
            <Snackbars options={snackbar} />
        </div>
    );
}
