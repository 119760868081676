import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootDasboard: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            [theme.breakpoints.up('xs')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
            },
        },
        cardContentRootCardDasboard: {
            height: 0,
        },
        mediaRootCardDasboard: {
            height: 260,
            width: '75%',
            padding: '0px 0px',
            marginTop: '-30px',
            [theme.breakpoints.down('xs')]: {
                height: 280,
                width: '70%',
                marginTop: '-20px',
            },
        },
        buttonRootCardDasboard: {
            height: '360px',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('xs')]: {
                height: '380px',
            },
        },
        rootCardDasboard: {
            maxWidth: 320,
            width: 320,
            margin: '16px',
            height: '360px',
            justifyContent: 'center',
            [theme.breakpoints.down('xs')]: {
                height: '380px',
            },
            [theme.breakpoints.up('xs')]: {
                maxWidth: '100%',
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 320,
                width: 320,
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: 320,
                width: 320,
            },
        },
        rootCard: {
            maxWidth: 320,
            width: 320,
            margin: '16px',
            height: '550px',
            [theme.breakpoints.up('xs')]: {
                maxWidth: '100%',
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 545,
                width: 545,
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: 320,
                width: 320,
            },
        },
        cardContent: {
            height: 115,
        },
        cardContentSesion: {
            height: 180,
        },
        media: {
            height: 340,
        },
        cardActions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        cardActions2: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cardDownloadIcon: {
            color: green[500],
        },
        displayNone: {
            display: 'none',
        },
    }),
);
