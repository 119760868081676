import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { modalTitleFontTheme, useGeneralStyles } from '../../../styles-matui';
import {
    Button,
    OutlinedInput,
    Avatar,
    AppBar,
    Tabs,
    Tab,
    ThemeProvider,
    Typography,
} from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { STATUS, VACIO, DATA_USER_INIT } from '../../../constants';
import { EDIT_USER } from '../usuarios.graphql';
import { useMutation } from '@apollo/client';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import WorkIcon from '@material-ui/icons/Work';
import { EditUsuario } from '../usuarios.interfaces';
import Spinner from '../../spinner/spinner.compt';
import Snackbars from '../../snackbar/snackbar.compt';
import { DataControlModalParent } from '../../../interfaces';
import SchoolIcon from '@material-ui/icons/School';
import { useStyles } from '../usuarios.styles-matui';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import HomeIcon from '@material-ui/icons/Home';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RoomIcon from '@material-ui/icons/Room';
import DomainIcon from '@material-ui/icons/Domain';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import './editar-usuario.sass';
import { themeButtons } from '../../../theme.styles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ModalEditarUsuario(props) {
    const classes = useGeneralStyles();
    const classesUer = useStyles();
    const dataInit: EditUsuario = DATA_USER_INIT;

    const [formData, setFormData] = useState<EditUsuario>(dataInit);
    const [updUser] = useMutation(EDIT_USER);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [snackbar, setSnackbar] = useState<DataControlModalParent>({
        open: false,
    });
    const [tab, setTab] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (formData.idUsuario === VACIO && props.open) {
            setFormData(props.dataUser);
        }
    });

    const submitAdmin = () => {
        setOpenSpinner(true);

        const foto =
            'https://firebasestorage.googleapis.com/v0/b/mvac-dev.appspot.com/o/perfiles%2F[id]%2F[nombre]?alt=media';
        let userData: any = { ...formData };

        if (formData.fileFoto) {
            userData = {
                ...userData,
                foto: foto
                    .replace(
                        '[id]',
                        formData.idUsuario ? formData.idUsuario : '',
                    )
                    .replace(
                        '[nombre]',
                        formData.nombreFoto ? formData.nombreFoto : '',
                    ),
            };
        }

        delete userData.fechaAlta;
        delete userData.curriculum;
        delete userData.banInactivo;
        delete userData.nombreCompleto;
        delete userData.cursos;
        delete userData.fileFoto;
        delete userData.nombreFoto;

        updUser({ variables: { user: userData } })
            .then(() => {
                setTab(0);
                setFormData(dataInit);
                setOpenSpinner(false);
                props.closeParent(true);
            })
            .catch(() => {
                props.closeParent(false);
                openSnackBar(`Error al actualizar usuario`, STATUS.error);
                setOpenSpinner(false);
            });
    };

    const onChangeForm =
        (prop: keyof EditUsuario) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFormData({ ...formData, [prop]: event.target.value });
        };

    const onClickDialogOpenClose = () => {
        setTab(0);
        setFormData(dataInit);
        props.closeParent();
    };

    const closeSnackbar = () => {
        setSnackbar({ open: false, closeParent: closeSnackbar });
    };

    const openSnackBar = (mensaje: string, status: string) => {
        setSnackbar({
            status,
            open: true,
            descripcion: mensaje,
            closeParent: closeSnackbar,
        });
    };

    const uploadImg = (event) => {
        const file = event.target.files[0];
        const fileReader: FileReader = new FileReader();
        const name = event.target.files[0].name;

        fileReader.onloadend = () => {
            setFormData({
                ...formData,
                foto: fileReader.result,
                fileFoto: file,
                nombreFoto: name,
            });

            event.target.value = null;
        };

        fileReader.readAsDataURL(file);
    };

    return (
        <div>
            <Spinner
                class={openSpinner ? 'displaySpinner' : 'displayNoneSpinner'}
            />

            <Dialog
                maxWidth='md'
                scroll='body'
                open={props.open}
                className={classes.modal}
                onClose={onClickDialogOpenClose}
                aria-labelledby='form-dialog-title'
            >
                <div>
                    <DialogTitle
                        id='form-dialog-title'
                        className={classes.titleForm}
                    >
                        <ThemeProvider theme={modalTitleFontTheme}>
                            <Typography
                                variant='h5'
                                component='div'
                                color='primary'
                            >
                                {formData.banPerfil
                                    ? 'INFORMACIÓN PERSONAL'
                                    : 'INFORMACIÓN DE USUARIO'}
                            </Typography>
                        </ThemeProvider>
                    </DialogTitle>
                    <DialogContent>
                        <div
                            className={
                                formData.banPerfil
                                    ? classesUer.containerFotoPerfil
                                    : classesUer.displayNone
                            }
                        >
                            <input
                                accept='image/*'
                                className={classes.fotoInput}
                                id='fotoPerfil'
                                onChange={uploadImg}
                                type='file'
                            />

                            <label htmlFor='fotoPerfil'>
                                <Button color='primary' component='span'>
                                    Seleccionar foto de perfil
                                </Button>
                            </label>

                            <Avatar
                                className={classesUer.avatarPerfil}
                                alt={formData.nombres}
                                src={formData.foto ? formData.foto : VACIO}
                            />
                        </div>
                        <AppBar
                            position='static'
                            className={
                                formData.banPerfil
                                    ? classesUer.tabsDataUser
                                    : classesUer.displayNone
                            }
                        >
                            <Tabs
                                value={tab}
                                onChange={handleChange}
                                aria-label='personal info user'
                                variant='scrollable'
                                scrollButtons='on'
                            >
                                <Tab
                                    className={classesUer.tabText}
                                    label='General'
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    className={classesUer.tabText}
                                    label='Escolaridad y Estado Cívil'
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    className={classesUer.tabText}
                                    label='Empleos'
                                    {...a11yProps(2)}
                                />
                                <Tab
                                    className={classesUer.tabText}
                                    label='Localidad'
                                    {...a11yProps(3)}
                                />
                            </Tabs>
                        </AppBar>
                        <div
                            className={
                                tab === 0
                                    ? 'containerModalAdmin'
                                    : classesUer.displayNone
                            }
                        >
                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='idDashboard'>
                                        Id de Dashboard
                                    </InputLabel>
                                    <OutlinedInput
                                        id='idDashboard'
                                        type='text'
                                        value={formData.idDashboard}
                                        onChange={onChangeForm('idDashboard')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PersonIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={125}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='nombres'>
                                        Nombre(s)
                                    </InputLabel>
                                    <OutlinedInput
                                        id='nombres'
                                        type='text'
                                        value={formData.nombres}
                                        onChange={onChangeForm('nombres')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PersonIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={83}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='apellidos'>
                                        Apellidos
                                    </InputLabel>
                                    <OutlinedInput
                                        id='apellidos'
                                        type='text'
                                        value={formData.apellidos}
                                        onChange={onChangeForm('apellidos')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PersonIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={68}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='correo'>
                                        Correo electronico
                                    </InputLabel>
                                    <OutlinedInput
                                        id='correo'
                                        type='email'
                                        value={formData.correo}
                                        onChange={onChangeForm('correo')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <MailIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={135}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='celular'>
                                        Celular
                                    </InputLabel>
                                    <OutlinedInput
                                        id='celular'
                                        type='tel'
                                        value={formData.celular}
                                        onChange={onChangeForm('celular')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PhoneIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={51}
                                    />
                                </FormControl>
                            </div>

                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='dependencia'>
                                        Empresa/Escuela
                                    </InputLabel>
                                    <OutlinedInput
                                        id='dependencia'
                                        type='text'
                                        value={formData.dependencia}
                                        onChange={onChangeForm('dependencia')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <WorkIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={128}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='nivelAcademico'>
                                        Nivel acádemico
                                    </InputLabel>
                                    <OutlinedInput
                                        id='nivelAcademico'
                                        type='text'
                                        value={formData.nivelAcademico}
                                        onChange={onChangeForm(
                                            'nivelAcademico',
                                        )}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <SchoolIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={118}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='carreraProfesion'>
                                        Carrera/Profesión
                                    </InputLabel>
                                    <OutlinedInput
                                        id='carreraProfesion'
                                        type='text'
                                        value={formData.carreraProfesion}
                                        onChange={onChangeForm(
                                            'carreraProfesion',
                                        )}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <SchoolIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={130}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={
                                        formData.banPerfil
                                            ? classesUer.displayNone
                                            : classes.formControl
                                    }
                                >
                                    <InputLabel htmlFor='puesto'>
                                        Puesto
                                    </InputLabel>
                                    <OutlinedInput
                                        id='puesto'
                                        type='text'
                                        value={formData.puesto}
                                        onChange={onChangeForm('puesto')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <WorkIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={50}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={
                                        formData.banPerfil
                                            ? classes.formControl
                                            : classesUer.displayNone
                                    }
                                >
                                    <InputLabel htmlFor='edad'>Edad</InputLabel>
                                    <OutlinedInput
                                        id='edad'
                                        type='text'
                                        value={formData.edad}
                                        onChange={onChangeForm('edad')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <CalendarTodayIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={38}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div
                            className={
                                tab === 1
                                    ? 'containerModalAdmin'
                                    : classesUer.displayNone
                            }
                        >
                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='gradoEstudios'>
                                        Grado de estudios
                                    </InputLabel>
                                    <OutlinedInput
                                        id='gradoEstudios'
                                        type='text'
                                        placeholder='Secundaria, preparatoria, universidad'
                                        value={formData.gradoEstudios}
                                        onChange={onChangeForm('gradoEstudios')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <SchoolIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={133}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='escuela'>
                                        Última escuela a la que asistio
                                    </InputLabel>
                                    <OutlinedInput
                                        id='escuela'
                                        type='text'
                                        placeholder='Instituto Tecnológico de Ocotlán'
                                        value={formData.escuela}
                                        onChange={onChangeForm('escuela')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <DomainIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={220}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='carrera'>
                                        Carrera (en caso de aplicar)
                                    </InputLabel>
                                    <OutlinedInput
                                        id='carrera'
                                        type='email'
                                        placeholder='Ing. Electromecánica'
                                        value={formData.carrera}
                                        onChange={onChangeForm('carrera')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <SchoolIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={202}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='titulo'>
                                        ¿Estás titulado? (en caso de aplicar)
                                    </InputLabel>
                                    <OutlinedInput
                                        id='titulo'
                                        type='text'
                                        value={formData.titulo}
                                        onChange={onChangeForm('titulo')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <SchoolIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={262}
                                    />
                                </FormControl>
                            </div>

                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='curso1'>
                                        Curso ó capacitacion
                                    </InputLabel>
                                    <OutlinedInput
                                        id='curso1'
                                        type='text'
                                        placeholder='Reparador aire acondicionado'
                                        value={formData.curso1}
                                        onChange={onChangeForm('curso1')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <AccountBalanceIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={153}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='curso2'>
                                        Curso ó capacitacion
                                    </InputLabel>
                                    <OutlinedInput
                                        id='curso2'
                                        type='text'
                                        placeholder='Especialista en ventilación'
                                        value={formData.curso2}
                                        onChange={onChangeForm('curso2')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <AccountBalanceIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={153}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='estadoCivil'>
                                        Estado cívil
                                    </InputLabel>
                                    <OutlinedInput
                                        id='estadoCivil'
                                        type='text'
                                        value={formData.estadoCivil}
                                        onChange={onChangeForm('estadoCivil')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PersonIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={83}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='personasDependientes'>
                                        ¿Cuantas personas dependen de ti?
                                    </InputLabel>
                                    <OutlinedInput
                                        id='personasDependientes'
                                        type='text'
                                        value={formData.personasDependientes}
                                        onChange={onChangeForm(
                                            'personasDependientes',
                                        )}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <SupervisorAccountIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={258}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div
                            className={
                                tab === 2
                                    ? 'containerModalAdmin'
                                    : classesUer.displayNone
                            }
                        >
                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='empleoActual'>
                                        ¿Actualmente trabajas?
                                    </InputLabel>
                                    <OutlinedInput
                                        id='empleoActual'
                                        type='text'
                                        value={formData.empleoActual}
                                        onChange={onChangeForm('empleoActual')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <WorkIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={170}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='empleo'>
                                        Último o actual empleo
                                    </InputLabel>
                                    <OutlinedInput
                                        id='empleo'
                                        type='text'
                                        value={formData.empleo}
                                        onChange={onChangeForm('empleo')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <WorkIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={168}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='puesto1'>
                                        Puesto
                                    </InputLabel>
                                    <OutlinedInput
                                        id='puesto1'
                                        type='text'
                                        placeholder='Supervisor, técnico, auxiliar'
                                        value={formData.puesto}
                                        onChange={onChangeForm('puesto')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <PersonIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={52}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='responsabilidades'>
                                        Responsabilidades
                                    </InputLabel>
                                    <OutlinedInput
                                        id='responsabilidades'
                                        type='text'
                                        value={formData.responsabilidades}
                                        onChange={onChangeForm(
                                            'responsabilidades',
                                        )}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <AssignmentIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={140}
                                    />
                                </FormControl>
                            </div>

                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='duracion'>
                                        Duración
                                    </InputLabel>
                                    <OutlinedInput
                                        id='duracion'
                                        type='tel'
                                        placeholder='1 año, 2 meses, 5 años'
                                        value={formData.duracion}
                                        onChange={onChangeForm('duracion')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <AccessTimeIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={65}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='salarioActual'>
                                        Salario apróximado
                                    </InputLabel>
                                    <OutlinedInput
                                        id='salarioActual'
                                        type='tel'
                                        placeholder='$3000 quincenales'
                                        value={formData.salarioActual}
                                        onChange={onChangeForm('salarioActual')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <AttachMoneyIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={140}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='buscaEmpleo'>
                                        ¿Actualmente búscas empleo?
                                    </InputLabel>
                                    <OutlinedInput
                                        id='buscaEmpleo'
                                        type='tel'
                                        value={formData.buscaEmpleo}
                                        onChange={onChangeForm('buscaEmpleo')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <WorkIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={221}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='salarioDeseado'>
                                        Salario deseado (en caso de aplicar)
                                    </InputLabel>
                                    <OutlinedInput
                                        id='salarioDeseado'
                                        type='tel'
                                        placeholder='$3500 quincenales'
                                        value={formData.salarioDeseado}
                                        onChange={onChangeForm(
                                            'salarioDeseado',
                                        )}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <AttachMoneyIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={263}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div
                            className={
                                tab === 3
                                    ? 'containerModalAdmin'
                                    : classesUer.displayNone
                            }
                        >
                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='ciudad'>
                                        Ciudad
                                    </InputLabel>
                                    <OutlinedInput
                                        id='ciudad'
                                        type='text'
                                        value={formData.ciudad}
                                        onChange={onChangeForm('ciudad')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <LocationCityIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={53}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='cp'>
                                        Código postal
                                    </InputLabel>
                                    <OutlinedInput
                                        id='cp'
                                        type='text'
                                        value={formData.cp}
                                        onChange={onChangeForm('cp')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <RoomIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={102}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='colonia'>
                                        Colonia
                                    </InputLabel>
                                    <OutlinedInput
                                        id='colonia'
                                        type='email'
                                        value={formData.colonia}
                                        onChange={onChangeForm('colonia')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <HomeIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={56}
                                    />
                                </FormControl>

                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='domicilio'>
                                        Domicilio
                                    </InputLabel>
                                    <OutlinedInput
                                        id='domicilio'
                                        type='text'
                                        value={formData.domicilio}
                                        onChange={onChangeForm('domicilio')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <HomeIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={68}
                                    />
                                </FormControl>
                            </div>

                            <div className='containerModal'>
                                <FormControl
                                    variant='outlined'
                                    className={classes.formControl}
                                >
                                    <InputLabel htmlFor='transporte'>
                                        Medio de transporte
                                    </InputLabel>
                                    <OutlinedInput
                                        id='transporte'
                                        type='text'
                                        value={formData.transporte}
                                        onChange={onChangeForm('transporte')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <DirectionsCarIcon color='primary' />
                                            </InputAdornment>
                                        }
                                        labelWidth={147}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className={classes.dialogActions}>
                        <div className='containerActions'>
                            <ThemeProvider theme={themeButtons}>
                                <Button
                                    onClick={submitAdmin}
                                    color='primary'
                                    variant='contained'
                                >
                                    GUARDAR
                                </Button>
                            </ThemeProvider>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
            <Snackbars options={snackbar} />
        </div>
    );
}
