import { gql } from '@apollo/client';

export const GET_USERS = gql`
    query {
        getUsers {
            fechaAlta
            administrador
            foto
            nombres
            apellidos
            celular
            correo
            dependencia
            nivelAcadem
            carreraProf
            puesto
            curriculum
            tipo
            idDashboard
        }
    }
`;

export const ADD_USER = gql`
    mutation createNewUser($user: newUser!) {
        createUser(user: $user) {
            nombres
            apellidos
            celular
            correo
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteAdministrador($user: UserSimpleAction!) {
        deleteAdmin(user: $user)
    }
`;

export const INHABILITAR_USER = gql`
    mutation inhabilitarUser($user: UserSimpleAction!) {
        inhabilitarUsuario(user: $user)
    }
`;

export const HABILITAR_USER = gql`
    mutation habilitarUser($user: UserSimpleAction!) {
        habilitarUsuario(user: $user)
    }
`;

export const UPDATE_PASSWORD = gql`
    mutation actualizarPass($dataPassword: RestablecerPass!) {
        restablecerContra(dataPassword: $dataPassword)
    }
`;

export const LOGIN_ADMIN = gql`
    mutation nuevoLoginAdministrador($login: Login!) {
        iniciarSesionAdministrador(login: $login) {
            idAdministrador
            fechaAlta
            superior
            nombres
            apellidos
            correo
            puesto
            tipo
            permisos
            foto
            celular
            banInactivo
            nombreCompleto
            cursos {
                idCurso
                permisos
            }
        }
    }
`;

export const LOGIN_USER = gql`
    mutation nuevoLoginUsuario($login: Login!) {
        iniciarSesionUsuario(login: $login) {
            idUsuario
            nombres
            apellidos
            celular
            correo
            dependencia
            nivelAcademico
            carreraProfesion
            puesto
            idAdministrador
            fechaAlta
            foto
            curriculum
            tipo
            banInactivo
            nombreCompleto
            edad
            gradoEstudios
            escuela
            carrera
            titulo
            curso1
            curso2
            estadoCivil
            personasDependientes
            empleoActual
            empleo
            responsabilidades
            duracion
            salarioActual
            buscaEmpleo
            salarioDeseado
            ciudad
            cp
            colonia
            domicilio
            transporte
            cursos {
                idCurso
                fechaAlta
                porcentaje
                banDiploma
                modulos {
                    idModulo
                    fechaAlta
                    fechaTermino
                    porcentaje
                    banDiploma
                    sesiones {
                        idSesion
                        fechaAlta
                        fechaTermino
                        porcentaje
                        banDescargaDocumento
                        videos {
                            idVideo
                            currentTime
                            porcentaje
                        }
                        examen {
                            intento
                            calificacion
                            preguntas {
                                pregunta
                                respuesta
                                correcta
                            }
                        }
                    }
                }
            }
        }
    }
`;
